import React, { useContext, createContext, useState } from "react"

const ContentContext = createContext()

export function ContentProvider({ children }) {
  const content = useContentProvider()
  return (
    <ContentContext.Provider value={content}>
      {children}
    </ContentContext.Provider>
  )
}

export const useContent = () => {
  return useContext(ContentContext)
}

function useContentProvider() {
  const [procedure, setProcedure] = useState({})
  const [searchResult, setSearchResult] = useState("")

  function saveProcedure(setProcedureParam) {
    setProcedure(setProcedureParam)
  }

  function saveSearch(setSearchParam) {
    setSearchResult(setSearchParam)
  }

  return {
    procedure,
    searchResult,
    saveProcedure,
    saveSearch,
  }
}
