import React, { useEffect, useState, Fragment } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import axiosInstance from "../../../utils/axios"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const EditInstitution = () => {
  const navigate = useNavigate()
  const { t } = useTranslation("translation")
  const params = useParams()
  const [institutionName, setInstitutionName] = useState("")
  const [institutionTypes, setInstitutionTypes] = useState([])
  const [selected, setSelected] = useState({})

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/admin/institutionTypes`,
    })
      .then((res) => {
        setInstitutionTypes(res.data)
      })
      .catch((err) => {
        err && navigate("/dashboard")
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/admin/institutions/handle`,
      params: {
        id: params.id,
      },
    })
      .then((res) => {
        setInstitutionName(res.data.name)
        setSelected(res.data.institutionType)
      })
      .catch((err) => {
        err && navigate("/dashboard")
      })
    // eslint-disable-next-line
  }, [params])

  const handleSave = () => {
    const data = {
      id: params.id,
      name: institutionName,
      type_id: selected.id,
    }

    axiosInstance({
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}/api/admin/institutions`,
      data: data,
    })
      .then((res) => {
        res.data === "success" && navigate("/admin/institutions")
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
        <span className="text-2xl font-semibold text-gray-900">
          {t("button.edit")} {t("profile.institution")}
        </span>
        <div className="md:mt-0 mt-4">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            onClick={() => {
              navigate("/admin/institutions")
            }}
          >
            {t("button.back")}
          </button>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="mt-5 md:mt-0">
            <div className="shadow sm:overflow-visible sm:rounded-md outline outline-1 outline-offset-0 outline-gray-200 sm:mx-0 -mx-4">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="col-span-6 md:grid md:grid-cols-4 items-center">
                  <p className="md:col-span-1 block text-sm font-medium text-gray-700">
                    {t("admin.institution.name")}
                  </p>
                  <input
                    type="text"
                    className="md:col-span-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                    value={institutionName}
                    onChange={(e) => {
                      setInstitutionName(e.target.value)
                    }}
                  />
                </div>

                <div className="hidden sm:block" aria-hidden="true">
                  <div className="border-t border-gray-200" />
                </div>

                <div className="col-span-6 md:grid md:grid-cols-4 items-center">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 md:col-span-1">
                          {t("admin.institution.type")}
                        </Listbox.Label>
                        <div className="relative md:col-span-3">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6">
                            <span className="block truncate">
                              {selected.title}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {institutionTypes.map((institutionType) => (
                                <Listbox.Option
                                  key={institutionType.id}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "bg-sky-600 text-white"
                                        : "text-gray-900",
                                      "relative cursor-default select-none py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={institutionType}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {institutionType.title}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-sky-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>
            <div className="pt-3 text-right">
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                onClick={handleSave}
              >
                {t("button.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
