import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import { Combobox } from "@headlessui/react"
import axiosInstance from "../../../utils/axios"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const CreateTag = () => {
  const navigate = useNavigate()
  const { t } = useTranslation("translation")
  const [specialties, setSpecialties] = useState([])
  const [tagName, setTagName] = useState("")
  const [specialty, setSpecialty] = useState({})
  const [query, setQuery] = useState("")

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/admin/specialties`,
    })
      .then((res) => {
        setSpecialties(res.data)
      })
      .catch((err) => {
        err && navigate("/dashboard")
      })
    // eslint-disable-next-line
  }, [])

  const filteredSpecialties =
    query === ""
      ? specialties
      : specialties.filter((specialty) => {
          return specialty.title.toLowerCase().includes(query.toLowerCase())
        })

  const handleSave = () => {
    const data = {
      name: tagName,
      specialty: specialty,
    }

    axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/admin/tags`,
      data: data,
    })
      .then((res) => {
        res.data === "success" && navigate("/admin/tags")
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
        <span className="text-2xl font-semibold text-gray-900">
          {t("button.add")} {t("mdlogbook.tag")}
        </span>
        <div className="md:mt-0 mt-4">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            onClick={() => {
              navigate("/admin/tags")
            }}
          >
            {t("button.back")}
          </button>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="mt-5 md:mt-0">
            <div className="shadow sm:overflow-visible sm:rounded-md outline outline-1 outline-offset-0 outline-gray-200 sm:mx-0 -mx-4">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="col-span-6 md:grid md:grid-cols-4 items-center">
                  <p className="md:col-span-1 block text-sm font-medium text-gray-700">
                    {t("admin.tag.name")}
                  </p>
                  <input
                    type="text"
                    className="md:col-span-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                    value={tagName}
                    onChange={(e) => {
                      setTagName(e.target.value)
                    }}
                    required
                  />
                </div>

                <div className="hidden sm:block" aria-hidden="true">
                  <div className="border-t border-gray-200" />
                </div>

                <div className="col-span-6 md:grid md:grid-cols-4 items-center">
                  <p className="md:col-span-1 block text-sm font-medium text-gray-700">
                    {t("admin.tag.specialty")}
                  </p>
                  <Combobox
                    as="div"
                    value={specialty}
                    onChange={setSpecialty}
                    className="md:col-span-3"
                  >
                    <div className="relative">
                      <Combobox.Input
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(specialty) => specialty?.title}
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Combobox.Button>

                      {filteredSpecialties.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {filteredSpecialties.map((specialty) => (
                            <Combobox.Option
                              key={specialty.id}
                              value={specialty}
                              className={({ active }) =>
                                classNames(
                                  "relative cursor-default select-none py-2 pl-3 pr-9",
                                  active
                                    ? "bg-sky-600 text-white"
                                    : "text-gray-900"
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <span
                                    className={classNames(
                                      "block truncate",
                                      selected && "font-semibold"
                                    )}
                                  >
                                    {specialty.title}
                                  </span>

                                  {selected && (
                                    <span
                                      className={classNames(
                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                        active ? "text-white" : "text-sky-600"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}
                    </div>
                  </Combobox>
                </div>
              </div>
            </div>
            <div className="pt-3 text-right">
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                onClick={handleSave}
              >
                {t("button.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
