import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { useAuth } from "../../context/useAuth"
import { logos } from "../../assets"

export const Login = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const auth = useAuth()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [msg, setMsg] = useState("")
  const [isError, setIsError] = useState(false)

  const Signin = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/login`, {
        email: email,
        password: password,
      })
      if (response.data.accessToken) {
        auth.saveAccount(response.data)
        navigate("/dashboard")
      }
    } catch (error) {
      if (error.response) {
        setMsg(t("login.wrongCredential"))
        setIsError(true)
      }
    }
  }
  return (
    <>
      <div className="flex min-h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto cursor-pointer"
              src={logos.DarkLogo}
              alt="MDLogBook"
              onClick={() => {
                navigate("/")
              }}
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              {t("login.signin")}
            </h2>
            <p className="text-center text-red-600 font-semibold pt-5">{msg}</p>
          </div>
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={Signin}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("login.emailAddress")}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                    className={
                      "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm " +
                      (isError ? "border-red-500" : "")
                    }
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("login.password")}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className={
                      "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm " +
                      (isError ? "border-red-500" : "")
                    }
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    {t("login.rememberMe")}
                  </label>
                </div>

                <div className="text-sm">
                  <Link
                    to="/forgot-password"
                    className="font-medium text-sky-600 hover:text-sky-500"
                  >
                    {t("login.forgotPassword")}
                  </Link>
                </div>
              </div>

              <div>
                <button className="flex w-full justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                  {t("navbar.login")}
                </button>
              </div>

              <p className="text-center">
                {t("login.doNotHaveAccount")}{" "}
                <span>
                  <Link to="/" className="text-sky-700">
                    {t("navbar.register")}
                  </Link>
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
