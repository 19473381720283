import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useContent } from "../../context/useContent"
import axiosInstance from "../../utils/axios"

export const GlobalSearch = () => {
  const savedData = useContent()
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const id = localStorage.getItem("id")
  const app = "mdlogbook"
  const settingkey = "procedure"
  const [originalProcedures, setOriginalProcedures] = useState([])
  const [procedures, setProcedures] = useState([])
  const [allCases, setAllCases] = useState([])
  const [cases, setCases] = useState([])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/medical-procedures/all`,
      params: {
        userid: id,
        app: app,
        settingkey: settingkey,
      },
    })
      .then((res) => {
        setOriginalProcedures(
          res.data.medicalprocedures.map((item) => {
            return {
              ...item,
              checked: !res.data.value.filter((valueItem) => {
                return Number(valueItem) === Number(item.id)
              }).length
                ? true
                : false,
            }
          })
        )
      })
      .catch((err) => {
        console.log(err)
      })

    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/cases/all`,
      params: {
        userId: id,
      },
    })
      .then((res) => {
        setAllCases(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id, settingkey, app])

  useEffect(() => {
    setProcedures(
      originalProcedures.filter((item) =>
        item.title.toLowerCase().includes(savedData.searchResult.toLowerCase())
      )
    )
  }, [originalProcedures, savedData.searchResult])

  useEffect(() => {
    setCases(
      allCases.filter(
        (item) =>
          item.medicalprocedure.title
            .toLowerCase()
            .includes(savedData.searchResult.toLowerCase()) ||
          (item.diagnosis &&
            item.diagnosis
              .toLowerCase()
              .includes(savedData.searchResult.toLowerCase()))
      )
    )
  }, [savedData.searchResult, allCases])

  return (
    <>
      {/* MDLogBook part */}
      {(procedures.length > 0 || cases.length > 0) && (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <span className="text-2xl font-semibold text-gray-900 md:mb-0 mb-4">
            {t("sidebar.mdlogbook")}
          </span>
        </div>
      )}
      {(procedures.length > 0 || cases.length > 0) && (
        <div>
          <div className="mx-auto max-w-7xl px-0 sm:px-6 md:px-8">
            <div className="py-4">
              <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full">
                  <thead></thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {procedures.length > 0 && procedures.map((item, index) => index < 5 && (
                      <tr key={item.id}>
                        <td
                          className="whitespace-nowrap px-4 py-4 text-sm text-gray-900 cursor-pointer"
                          onClick={() => {
                            localStorage.setItem("procedureId", item.id)
                            navigate("/mdlogbook/new-case")
                          }}
                        >
                          <span className="font-medium">
                            {t("search.newCase")}
                          </span>
                          : &nbsp;&nbsp;{item.medicalproceduregroup.title} –{" "}
                          {item.title}
                        </td>
                      </tr>
                    ))}
                    {cases.length > 0 && cases.map((item, idx) => idx < 5 && (
                      <tr key={item.id}>
                        <td
                          className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 cursor-pointer"
                          onClick={() => {
                            navigate(`/mdlogbook/cases/${item.id}`)
                          }}
                        >
                          <span className="font-medium">
                            {t("search.viewCase")}
                          </span>
                          : &nbsp;&nbsp;
                          {new Date(item.date).toISOString().slice(0, 10)}{" "}
                          –&nbsp;
                          {
                            item.medicalprocedure.medicalproceduregroup.title
                          } – {item.medicalprocedure.title}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
