import React, { useState, useEffect, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  CheckIcon,
  ChevronUpDownIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid"
import { CheckCircleIcon } from "@heroicons/react/24/outline"
import { Transition, Combobox, Dialog } from "@headlessui/react"
import "./index.css"
import axiosInstance from "../../utils/axios"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const CreateDiagnosis = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()

  const [procedure, setProcedure] = useState({})
  const [date, setDate] = useState(new Date())
  const [location, setLocation] = useState("")
  const [no, setNo] = useState("")
  const [dob, setDob] = useState("")
  const [age, setAge] = useState("")
  const [gender, setGender] = useState(null)
  const [diagnosis, setDiagnosis] = useState("")
  const [complication, setComplication] = useState("")
  const [outcome, setOutcome] = useState("success")
  const [duration, setDuration] = useState("")
  const [query, setQuery] = useState("")
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [people, setPeople] = useState([])
  const [reviewers, setReviewers] = useState([])
  const user_id = localStorage.getItem("id")
  const procedure_id = localStorage.getItem("procedureId")
  const [show, setShow] = useState(false) // to show 'save successful' notification
  const [isConfirmOpen, setIsConfirmOpen] = useState(false) // for confirmation dialog
  const [isSaveAndNew, setIsSaveAndNew] = useState(false) // to differentiate between save and save & new
  // to select tags
  const [tagQuery, setTagQuery] = useState("")
  const [selectedTag, setSelectedTag] = useState(null)
  const [tags, setTags] = useState([])
  const [caseTags, setCaseTags] = useState([]) // selected tags by doctors

  const filteredTags =
    tagQuery === ""
      ? tags
      : tags.filter((tag) => {
          return tag.name.toLowerCase().includes(tagQuery.toLowerCase())
        })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/medical-procedures/current`,
      params: {
        procedureId: procedure_id,
      },
    })
      .then((res) => {
        setProcedure(res.data)
      })
      .catch((err) => console.log(err))

    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/tags/all`,
      params: {
        procedureId: procedure_id,
      },
    })
      .then((res) => {
        setTags(res.data)
      })
      .catch((err) => console.log(err))
  }, [procedure_id])
  // end

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/userId/${user_id}`,
    })
      .then((res) => {
        res.data.location === null
          ? setLocation("")
          : setLocation(res.data.location)
      })
      .catch((err) => console.log(err))
  }, [user_id])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/all`,
    })
      .then((res) => {
        const temp = res.data.filter((item) => item.id !== Number(user_id))
        setPeople(temp)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [user_id])

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
          const fullName = person.firstname + " " + person.lastname
          const lists =
            person.username.toLowerCase().includes(query.toLowerCase()) ||
            fullName.toLowerCase().includes(query.toLowerCase())
          return lists
        })

  useEffect(() => {
    query === "" && setSelectedPerson(null)
  }, [query])

  useEffect(() => {
    tagQuery === "" && setSelectedTag(null)
  }, [tagQuery])

  // calculate age
  const calculate_age = (dob) => {
    const diff_ms = Date.now() - dob.getTime()
    const age_dt = new Date(diff_ms)
    return Math.abs(age_dt.getUTCFullYear() - 1970)
  }

  // to close the 'successful save' notification after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false)
    }, 5000)

    return () => clearTimeout(timeout)
  }, [show])

  // function to handle saving the case
  const handleSave = () => {
    let data = {}
    let tempDuration = 0
    if (duration === "") {
      tempDuration = 0
    } else {
      tempDuration = duration
    }
    if (dob === "") {
      if (selectedPerson && reviewers.length === 0) {
        data = {
          userid: user_id,
          medicalprocedureid: procedure.id,
          date: date,
          location: location,
          no: no,
          dob: new Date(),
          age: 0,
          gender: gender,
          diagnosis: diagnosis,
          complication: complication,
          outcome: outcome,
          duration: tempDuration,
          reviewers: [selectedPerson],
          caseTags: caseTags,
        }
      } else {
        data = {
          userid: user_id,
          medicalprocedureid: procedure.id,
          date: date,
          location: location,
          no: no,
          dob: new Date(),
          age: 0,
          gender: gender,
          diagnosis: diagnosis,
          complication: complication,
          outcome: outcome,
          duration: tempDuration,
          reviewers: reviewers,
          caseTags: caseTags,
        }
      }
    } else {
      if (selectedPerson && reviewers.length === 0) {
        data = {
          userid: user_id,
          medicalprocedureid: procedure.id,
          date: date,
          location: location,
          no: no,
          dob: dob,
          age: age,
          gender: gender,
          diagnosis: diagnosis,
          complication: complication,
          outcome: outcome,
          duration: tempDuration,
          reviewers: [selectedPerson],
          caseTags: caseTags,
        }
      } else {
        data = {
          userid: user_id,
          medicalprocedureid: procedure.id,
          date: date,
          location: location,
          no: no,
          dob: dob,
          age: age,
          gender: gender,
          diagnosis: diagnosis,
          complication: complication,
          outcome: outcome,
          duration: tempDuration,
          reviewers: reviewers,
          caseTags: caseTags,
        }
      }
    }
    axiosInstance({
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/location/save`,
      data: {
        userid: user_id,
        location: location,
      },
    })
      .then(() => {
        axiosInstance({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/cases/store`,
          data: data,
        })
          .then((res) => {
            if (res.data === "success") {
              if (isSaveAndNew) {
                setDate(new Date())
                setNo("")
                setDob("")
                setAge("")
                setGender("Male")
                setDiagnosis("")
                setComplication("")
                setOutcome("success")
                setDuration("")
                setReviewers([])
                setCaseTags([])
                setSelectedTag(null)
                setShow(true)
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              } else {
                navigate("/mdlogbook")
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
        <span className="text-2xl font-semibold text-gray-900">
          {procedure.title}
        </span>
        <div className="md:mt-0 mt-4">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            onClick={() => {
              navigate("/mdlogbook")
            }}>
            {t("button.back")}
          </button>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <div className="py-4">
          <div className="md:grid md:grid-cols-4 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t("mdlogbook.information")}
                </h3>
              </div>
            </div>
            <div className="mt-5 md:col-span-3 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md sm:mx-0 -mx-4">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-2 gap-6">
                    <div className="md:col-span-1 col-span-2">
                      <label className="block text-sm font-medium text-gray-700">
                        {t("mdlogbook.date")}
                      </label>
                      <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>

                    <div className="md:col-span-1 col-span-2">
                      <label className="block text-sm font-medium text-gray-700">
                        {t("mdlogbook.location")}
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("mdlogbook.patient")}
                  </h3>
                </div>
              </div>
              <div className="mt-5 md:col-span-3 md:mt-0">
                <div className="overflow-hidden shadow sm:rounded-md sm:mx-0 -mx-4">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-8 gap-8">
                      <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.medicalRecordNumber")}&nbsp;&nbsp;
                          <span className="text-sm font-normal text-gray-500">
                            ({t("mdlogbook.optional")})
                          </span>
                        </label>
                        <input
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                          value={no}
                          onChange={(e) => {
                            setNo(e.target.value)
                          }}
                        />
                      </div>

                      <div className="col-span-8 sm:col-span-4 xl:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.dateOfBirth")}&nbsp;&nbsp;
                          <span className="text-sm font-normal text-gray-500">
                            ({t("mdlogbook.optional")})
                          </span>
                        </label>
                        <DatePicker
                          selected={dob}
                          onChange={(date) => {
                            setDob(date)
                            setAge(calculate_age(new Date(date)))
                          }}
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>

                      <div className="col-span-8 sm:col-span-4 xl:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.age")}
                        </label>
                        <p className="mt-3">{age}</p>
                      </div>

                      <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                        <legend className="contents text-base font-medium text-gray-900">
                          {t("mdlogbook.gender")}
                        </legend>
                        <div className="mt-4 flex items-center">
                          <div className="flex items-center pr-2">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="Male"
                              checked={gender === "Male"}
                              onChange={(e) => {
                                setGender(e.target.value)
                              }}
                            />
                            <label className="md:ml-2 ml-4 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.male")}
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="Female"
                              checked={gender === "Female"}
                              onChange={(e) => {
                                setGender(e.target.value)
                              }}
                            />
                            <label className="md:ml-2 ml-4 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.female")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("mdlogbook.details")}
                  </h3>
                </div>
              </div>
              <div className="mt-5 md:col-span-3 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md sm:mx-0 -mx-4">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        {t("mdlogbook.tags")}
                      </label>
                      <div className="grid grid-cols-3 gap-3 mt-1">
                        <div className="col-span-3 lg:col-span-2">
                          <Combobox
                            as="div"
                            value={selectedTag}
                            onChange={setSelectedTag}>
                            <div className="relative">
                              <Combobox.Input
                                className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                                onChange={(event) =>
                                  setTagQuery(event.target.value)
                                }
                                displayValue={(tag) => tag?.name}
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </Combobox.Button>

                              {filteredTags.length > 0 && (
                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {filteredTags.map((tag) => (
                                    <Combobox.Option
                                      key={tag.id}
                                      value={tag}
                                      className={({ active }) =>
                                        classNames(
                                          "relative cursor-default select-none py-2 pl-3 pr-9",
                                          active
                                            ? "bg-sky-600 text-white"
                                            : "text-gray-900"
                                        )
                                      }>
                                      {({ active, selected }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              "block truncate",
                                              selected && "font-semibold"
                                            )}>
                                            {tag.name}
                                          </span>

                                          {selected && (
                                            <span
                                              className={classNames(
                                                "absolute inset-y-0 right-0 flex items-center pr-4",
                                                active
                                                  ? "text-white"
                                                  : "text-sky-600"
                                              )}>
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))}
                                </Combobox.Options>
                              )}
                            </div>
                          </Combobox>
                        </div>
                        <div className="col-span-3 lg:col-span-1">
                          <button
                            className={classNames(
                              "inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 bg-sky-600",
                              selectedTag
                                ? "hover:bg-sky-700"
                                : "hover:bg-sky-600 opacity-50 cursor-default"
                            )}
                            onClick={() => {
                              if (selectedTag) {
                                setCaseTags((_prev) => {
                                  const tmp = [..._prev]
                                  for (let i = 0; i < tmp.length; i++) {
                                    if (tmp[i].id === selectedTag.id) {
                                      return tmp
                                    }
                                  }
                                  tmp.push(selectedTag)
                                  return tmp
                                })
                                setSelectedTag(null)
                              } else {
                                setSelectedTag(null)
                                return
                              }
                            }}>
                            {t("button.add")} {t("mdlogbook.tag")}
                          </button>
                        </div>
                      </div>
                      <div className="mt-2">
                        {caseTags.length > 0 &&
                          caseTags.map((caseTag, index) => (
                            <span
                              key={index}
                              className="inline-flex items-center rounded-full bg-sky-100 py-0.5 px-3 text-sm font-medium text-sky-700 mr-2">
                              {caseTag.name}
                              <button
                                type="button"
                                className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-sky-400 hover:bg-sky-200 hover:text-sky-500 focus:bg-sky-500 focus:text-white focus:outline-none"
                                onClick={() => {
                                  setCaseTags((_prev) => {
                                    const tmp = [..._prev]
                                    const result = tmp.filter(
                                      (item) => item.id !== caseTag.id
                                    )
                                    return result
                                  })
                                }}>
                                <span className="sr-only">
                                  Remove large option
                                </span>
                                <svg
                                  className="h-2 w-2"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 8 8">
                                  <path
                                    strokeLinecap="round"
                                    strokeWidth="1.5"
                                    d="M1 1l6 6m0-6L1 7"
                                  />
                                </svg>
                              </button>
                            </span>
                          ))}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="col-span-2 xs:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.diagnosis-description")}
                        </label>
                        <div className="mt-1">
                          <textarea
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                            value={diagnosis}
                            onChange={(e) => {
                              setDiagnosis(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-span-2 xs:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("Complications")}
                        </label>
                        <div className="mt-1">
                          <textarea
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                            value={complication}
                            onChange={(e) => {
                              setComplication(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <fieldset className="col-span-2 lg:col-span-1">
                        <legend className="contents text-sm font-medium text-gray-700">
                          {t("mdlogbook.outcome")}&nbsp;&nbsp;
                        </legend>
                        <span className="text-sm text-gray-500">
                          ({t("mdlogbook.optional")})
                        </span>
                        <div className="mt-4 flex items-center">
                          <div className="flex items-center lg:pr-8 pr-2">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="success"
                              checked={outcome === "success"}
                              onChange={(e) => {
                                setOutcome(e.target.value)
                              }}
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.success")}
                            </label>
                          </div>
                          <div className="flex items-center lg:pr-8 pr-2">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="failure"
                              checked={outcome === "failure"}
                              onChange={(e) => {
                                setOutcome(e.target.value)
                              }}
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.failure")}
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="notApplicable"
                              checked={outcome === "notApplicable"}
                              onChange={(e) => {
                                setOutcome(e.target.value)
                              }}
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.notApplicable")}
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <div className="col-span-2 lg:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.duration")} (mins)&nbsp;&nbsp;
                          <span className="text-sm font-normal text-gray-500">
                            ({t("mdlogbook.optional")})
                          </span>
                        </label>
                        <input
                          type="number"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                          value={duration}
                          onChange={(e) => {
                            setDuration(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("mdlogbook.reviewer")}
                  </h3>
                </div>
              </div>
              <div className="mt-5 md:col-span-3 md:mt-0">
                <div className="shadow sm:overflow-visible sm:rounded-md outline outline-1 outline-offset-0 outline-gray-200 sm:mx-0 -mx-4">
                  <div className="space-y-2 bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-3 gap-3">
                      <div className="col-span-3 lg:col-span-1">
                        <Combobox
                          as="div"
                          value={selectedPerson}
                          onChange={setSelectedPerson}>
                          <div className="relative">
                            <Combobox.Input
                              className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                              onChange={(event) => setQuery(event.target.value)}
                              displayValue={(person) =>
                                person
                                  ? person.firstname + " " + person.lastname
                                  : ""
                              }
                              placeholder="Enter a name..."
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button>

                            {filteredPeople.length > 0 && (
                              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm top-auto bottom-full">
                                {filteredPeople.map((person) => (
                                  <Combobox.Option
                                    key={person.id}
                                    value={person}
                                    className={({ active }) =>
                                      classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                        active
                                          ? "bg-sky-600 text-white"
                                          : "text-gray-900"
                                      )
                                    }>
                                    {({ active, selected }) => (
                                      <>
                                        <div className="flex">
                                          <span
                                            className={classNames(
                                              "block truncate",
                                              selected && "font-semibold"
                                            )}>
                                            {person.firstname} {person.lastname}
                                          </span>
                                          <span
                                            className={classNames(
                                              "ml-2 truncate text-gray-500",
                                              active
                                                ? "text-sky-200"
                                                : "text-gray-500"
                                            )}>
                                            @{person.username}
                                          </span>
                                        </div>

                                        {selected && (
                                          <span
                                            className={classNames(
                                              "absolute inset-y-0 right-0 flex items-center pr-4",
                                              active
                                                ? "text-white"
                                                : "text-sky-600"
                                            )}>
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))}
                              </Combobox.Options>
                            )}
                          </div>
                        </Combobox>
                      </div>
                      <div className="col-span-3 lg:col-span-1">
                        <button
                          className={classNames(
                            "inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 bg-sky-600",
                            selectedPerson
                              ? "hover:bg-sky-700"
                              : "hover:bg-sky-600 opacity-50 cursor-default"
                          )}
                          onClick={() => {
                            if (selectedPerson) {
                              setReviewers((_prev) => {
                                const tmp = [..._prev]
                                for (let i = 0; i < tmp.length; i++) {
                                  if (tmp[i].id === selectedPerson.id) {
                                    return tmp
                                  }
                                }
                                tmp.push(selectedPerson)
                                return tmp
                              })
                              setSelectedPerson(null)
                            } else {
                              setSelectedPerson(null)
                              return
                            }
                          }}>
                          {t("button.add")}
                        </button>
                      </div>
                    </div>
                    <div>
                      {reviewers.length > 0 &&
                        reviewers.map((reviewer, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center rounded-full bg-sky-100 py-0.5 pl-1 pr-1 text-sm font-medium text-sky-700 mt-2 mr-2">
                            {reviewer.avatar ? (
                              <img
                                alt="avatar"
                                src={reviewer.avatar}
                                className="rounded-full w-8 h-8 mr-2"
                              />
                            ) : (
                              <UserIcon
                                className="text-white w-8 h-8 mr-2"
                                aria-hidden="true"
                              />
                            )}
                            <div>
                              {reviewer.firstname} {reviewer.lastname}
                            </div>
                            <button
                              type="button"
                              className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-sky-400 hover:bg-sky-200 hover:text-sky-500 focus:bg-sky-500 focus:text-white focus:outline-none"
                              onClick={() => {
                                setReviewers((_prev) => {
                                  const tmp = [..._prev]
                                  const result = tmp.filter(
                                    (item) => item.id !== reviewer.id
                                  )
                                  return result
                                })
                              }}>
                              <span className="sr-only">
                                Remove large option
                              </span>
                              <svg
                                className="h-2 w-2"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 8 8">
                                <path
                                  strokeLinecap="round"
                                  strokeWidth="1.5"
                                  d="M1 1l6 6m0-6L1 7"
                                />
                              </svg>
                            </button>
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pl-4 pt-3 text-right sm:pl-6">
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 mr-2"
              onClick={() => {
                setIsSaveAndNew(false)
                setIsConfirmOpen(true)
              }}>
              {t("button.save")} & {t("button.close")}
            </button>
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              onClick={() => {
                setIsSaveAndNew(true)
                setIsConfirmOpen(true)
              }}>
              {t("button.save")} & {t("button.new")}
            </button>
          </div>
          {/* Save successful notification */}
          <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:px-8 sm:py-20">
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
              {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
              <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-6 w-6 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          {t("modal.savedSuccessful")}!
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          {t("modal.caseSaved")}
                        </p>
                      </div>
                      <div className="ml-4 flex flex-shrink-0">
                        <button
                          type="button"
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                          onClick={() => {
                            setShow(false)
                          }}>
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
          {/* End Save successful notification */}

          {/* Confirmation Dialog */}
          <Transition.Root show={isConfirmOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setIsConfirmOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <CheckIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900">
                            {t("MDLogBook Confirmation")}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              {t("Are you sure you want to save?")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {
                            handleSave()
                            setIsConfirmOpen(false)
                          }}>
                          {t("Confirm")}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => setIsConfirmOpen(false)}>
                          {t("Cancel")}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* End Confirmation Dialog */}
        </div>
        {/* /End replace */}
      </div>
    </>
  )
}
