import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../utils/axios";

export const Notification = () => {
  const { t } = useTranslation("translation");
  const id = localStorage.getItem("id");
  const [reviewCount, setReviewCount] = useState('0');

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/reviews/counts`,
      params: {
        userid: id,
      },
    })
    .then((res) => {
      const count = res.data;
      console.log("Received review count: ", count);  // Log the received count
      setReviewCount(count.toString());
    })
    .catch((err) => {
      console.error('Error fetching review counts:', err);
    })
  }, [id]);

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
        <span className="text-2xl font-semibold text-gray-900">
          {t("notification.title")}
        </span>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <div className="py-4">
          <div className="mt-5 md:col-span-3 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md sm:mx-0 -mx-4">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="col-span-6">
                  You have {reviewCount} cases awaiting review
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
