import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import axiosInstance from "../../../utils/axios"

export const CreateInstitutionType = () => {
  const navigate = useNavigate()
  const { t } = useTranslation("translation")
  const [institutionTypeTitle, setInstitutionTypeTitle] = useState("")

  const handleSave = () => {
    if (institutionTypeTitle === "") {
      return
    } else {
      const data = {
        title: institutionTypeTitle,
      }

      axiosInstance({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/admin/institutiontypes`,
        data: data,
      })
        .then((res) => {
          res.data === "success" && navigate("/admin/institutiontypes")
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
        <span className="text-2xl font-semibold text-gray-900">
          {t("button.add")} {t("admin.institution.type")}
        </span>
        <div className="md:mt-0 mt-4">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            onClick={() => {
              navigate("/admin/institutiontypes")
            }}
          >
            {t("button.back")}
          </button>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="mt-5 md:mt-0">
            <div className="shadow sm:overflow-visible sm:rounded-md outline outline-1 outline-offset-0 outline-gray-200 sm:mx-0 -mx-4">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="col-span-6 md:grid md:grid-cols-4 items-center">
                  <p className="md:col-span-1 block text-sm font-medium text-gray-700">
                    {t("admin.institution.title")}
                  </p>
                  <input
                    type="text"
                    className="md:col-span-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                    value={institutionTypeTitle}
                    onChange={(e) => {
                      setInstitutionTypeTitle(e.target.value)
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="pt-3 text-right">
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                onClick={handleSave}
              >
                {t("button.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
