import React, { useEffect, useState, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Dialog, Transition } from "@headlessui/react"
import {
  PencilSquareIcon,
  TrashIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid"
import axiosInstance from "../../../utils/axios"

export const Specialty = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const [specialties, setSpecialties] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/admin/specialties`,
    })
      .then((res) => {
        setSpecialties(res.data)
      })
      .catch((err) => {
        err && navigate("/dashboard")
      })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
        <span className="text-2xl font-semibold text-gray-900">
          {t("admin.specialties")}
        </span>
        <div className="md:mt-0 mt-4">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            onClick={() => {
              navigate("/admin/specialties/new-specialty")
            }}
          >
            {t("button.add")}
          </button>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="my-6 flow-root md:hidden">
                <ul className="-my-5 divide-y divide-gray-200 border-y bg-white">
                  {specialties &&
                    specialties.map((specialty) => (
                      <li key={specialty.id} className="py-4 px-4">
                        <div className="flex items-center space-x-3">
                          <div className="min-w-0 flex-1">
                            <p className="truncate text-sm font-medium text-gray-900 flex">
                              <b>{t("admin.specialty.tag")}:</b>&nbsp;
                              {specialty.tag}
                            </p>
                            <p className="truncate text-sm font-medium text-gray-900 flex">
                              <b>{t("admin.specialty.title")}:</b>&nbsp;
                              {specialty.title}
                            </p>
                            <p className="truncate text-sm font-medium text-gray-900 flex">
                              <b>{t("admin.specialty.parent")}:</b>&nbsp;
                              {specialty.parentSpecialty.title}
                            </p>
                          </div>
                          <div>
                            <button
                              className="text-sky-600 hover:text-sky-900 px-2"
                              onClick={() => {
                                navigate(`/admin/specialties/${specialty.id}`)
                              }}
                            >
                              <PencilSquareIcon
                                className="-mr-1 ml-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              <span className="sr-only">, {specialty.id}</span>
                            </button>
                            <button
                              className="text-red-600 hover:text-red-900"
                              onClick={() => {
                                setOpen(true)
                              }}
                            >
                              <TrashIcon
                                className="-mr-1 ml-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              <span className="sr-only">, {specialty.id}</span>
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg hidden md:block">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {t("admin.specialty.tag")}
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("admin.specialty.title")}
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("admin.specialty.parent")}
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {specialties.map((specialty) => (
                      <tr key={specialty.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {specialty.tag}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {specialty.title}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {specialty.parentSpecialty.title}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pr-6 text-right text-sm font-medium flex items-center justify-end">
                          <button
                            className="text-sky-600 hover:text-sky-900 px-2"
                            onClick={() => {
                              navigate(`/admin/specialties/${specialty.id}`)
                            }}
                          >
                            <PencilSquareIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            <span className="sr-only">, {specialty.id}</span>
                          </button>
                          <button
                            className="text-red-600 hover:text-red-900"
                            onClick={() => {
                              setOpen(true)
                            }}
                          >
                            <TrashIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            <span className="sr-only">, {specialty.id}</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {t("modal.removeSpecialtyErrorTitle")}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t("modal.removeSpecialtyErrorContent")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                      onClick={() => setOpen(false)}
                    >
                      {t("button.ok")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
