import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { Header } from "../../components/Header"

export const Home = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [msg, setMsg] = useState("")
  const [isError, setIsError] = useState(false)

  const Register = async (e) => {
    e.preventDefault()
    const data = {
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    }
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users`, data)
      navigate("/signin")
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg)
        setIsError(true)
      }
    }
  }

  return (
    <div className="relative overflow-hidden bg-gray-800 min-h-screen">
      <div
        className="hidden sm:absolute sm:inset-0 sm:block"
        aria-hidden="true"
      >
        <svg
          className="absolute bottom-0 right-0 mb-48 translate-x-1/2 transform text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:translate-x-0 xl:transform-none"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div>
      <div className="relative pb-12 sm:pb-16">
        <Header />
        <main className="mt-12 sm:mt-16">
          <div className="mx-auto max-w-7xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                <div>
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl">
                    {t("homepage.mainTitle")}
                  </h1>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    {t("homepage.mainDescription")}
                  </p>
                </div>
              </div>
              <div className="mt-16 sm:mt-24 lg:col-span-6 lg:mt-0">
                <div className="bg-white sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-lg">
                  <div className="px-4 pt-4 pb-8 sm:px-10">
                    <form onSubmit={Register} autoComplete="off">
                      <p className="text-red-600 mb-5 text-center">{msg}</p>
                      <input
                        type="text"
                        placeholder="First name"
                        required
                        value={firstName}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                        onChange={(e) => {
                          setFirstName(e.target.value)
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Last name"
                        required
                        value={lastName}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm mt-5"
                        onChange={(e) => {
                          setLastName(e.target.value)
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Username"
                        required
                        value={userName}
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm mt-5 " +
                          (isError ? "border-red-500" : "")
                        }
                        onChange={(e) => {
                          setUserName(e.target.value)
                        }}
                      />
                      <input
                        type="email"
                        placeholder="Email"
                        required
                        value={email}
                        autoComplete="off"
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm mt-5 " +
                          (isError ? "border-red-500" : "")
                        }
                        onChange={(e) => {
                          setEmail(e.target.value)
                        }}
                      />
                      <input
                        type="password"
                        placeholder="Password"
                        required
                        value={password}
                        autoComplete="off"
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm mt-5 " +
                          (isError ? "border-red-500" : "")
                        }
                        onChange={(e) => {
                          setPassword(e.target.value)
                        }}
                      />
                      <input
                        type="password"
                        placeholder="Confirm password"
                        required
                        value={confirmPassword}
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm mt-5 " +
                          (isError ? "border-red-500" : "")
                        }
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                        }}
                      />
                      <div className="mt-5">
                        <button className="flex w-full justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                          {t("navbar.register")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
