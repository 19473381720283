import React, { useState, useEffect, Fragment, useRef } from "react"
import Cropper from "react-easy-crop"
import { useTranslation } from "react-i18next"
import { Listbox, Transition, Dialog } from "@headlessui/react"
import {
  CheckIcon,
  ChevronUpDownIcon,
  UserIcon,
  PlusCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"
import { RingLoader } from "react-spinners"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import axiosInstance from "../../utils/axios"
import { useAuth } from "../../context/useAuth"
import { useContent } from "../../context/useContent"
import { GlobalSearch } from "../GlobalSearch"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const languages = ["English", "Français", "汉语"]

const override = {
  borderColor: "#4f46e5",
  zIndex: 31,
}

export const Profile = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const auth = useAuth()
  const savedData = useContent()
  const [loading, setLoading] = useState(false) // for spinner toggle
  const [open, setOpen] = useState(false) // for success modal toggle
  const [cropModalOpen, setCropModalOpen] = useState(false) // for crop modal toggle
  const cancelButtonRef = useRef(null) // for crop modal toggle
  const [openEducationModal, setOpenEducationModal] = useState(false) // modal to create an education
  const [editEducationModal, setEditEducationModal] = useState(false) // modal to edit an education
  const [user, setUser] = useState({})
  const [language, setLanguage] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [username, setUsername] = useState("")
  const [designation, setDesignation] = useState("")
  const [avatarUrl, setAvatarUrl] = useState("")
  const [institutions, setInstitutions] = useState([]) // get all institutions/schools from the DB to show list
  const [selectedInstitution, setSelectedInstitution] = useState({
    name: "Select item",
  })
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [schoolErrMessage, setSchoolErrMessage] = useState(false)
  const [schools, setSchools] = useState([]) // get all schools which user finished
  const [educationId, setEducationId] = useState(0)
  const [selectedEducation, setSelectedEducation] = useState({})
  // to crop image
  const [image, setImage] = useState("")
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState(null)
  const aspectRatio = 1 / 1
  const [fileName, setFileName] = useState("")
  const [mimeType, setMimeType] = useState("")

  const urlToFile = async (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer()
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType })
      })
  }

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }

  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas")
    if (imgCroppedArea.width > imgCroppedArea.height) {
      canvasEle.width = imgCroppedArea.height
      canvasEle.height = imgCroppedArea.height
    } else {
      canvasEle.width = imgCroppedArea.width
      canvasEle.height = imgCroppedArea.width
    }

    const context = canvasEle.getContext("2d")

    let imageObj1 = new Image()
    imageObj1.src = image
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      )

      const dataURL = canvasEle.toDataURL("image/jpeg")
      urlToFile(dataURL, fileName, mimeType).then((file) => {
        const formData = new FormData()
        formData.append("avatar", file)
        setLoading(true)
        axiosInstance({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/api/users/update_avatar`,
          data: formData,
        })
          .then((res) => {
            // setAvatarUrl(`${process.env.REACT_APP_BASE_URL}/${res.data.path}`) // display file from local
            setAvatarUrl(`${res.data.location}`) // display file from DO
          })
          .catch((err) => {
            console.log(err)
            setAvatarUrl("")
          })
          .finally(() => {
            setLoading(false)
          })
      })
    }
  }

  const onCropCancel = () => {
    setImage("")
  }
  // end crop image section

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/institutions`,
    })
      .then((res) => {
        res.data.unshift({
          id: 0,
          name: "Select item",
          type: "medical-school",
        })
        setInstitutions(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [])

  const id = localStorage.getItem("id")

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/userId/${id}`,
    })
      .then((res) => {
        setUser(res.data)
        auth.saveUser(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/institutions/handle/${id}`,
    })
      .then((res) => {
        setSchools(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (educationId !== 0) {
      axiosInstance({
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/api/users/institutions/handle`,
        params: { educationId: educationId },
      })
        .then((res) => {
          setSelectedEducation(res.data)
          setSelectedInstitution(res.data.institution)
          res.data.date_start === null
            ? setStartDate(null)
            : setStartDate(new Date(res.data.date_start))
          res.data.date_end === null
            ? setEndDate(null)
            : setEndDate(new Date(res.data.date_end))
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [educationId])

  useEffect(() => {
    Object.keys(selectedEducation).length > 0 && setEditEducationModal(true)
  }, [selectedEducation])

  useEffect(() => {
    setLanguage(user.language)
    setFirstname(user.firstname)
    setLastname(user.lastname)
    setUsername(user.username)
    setDesignation(user.designation)
    setAvatarUrl(user.avatar)
  }, [user])

  const updateProfile = async (e) => {
    e.preventDefault()
    let data = {
      email: user.email,
      firstname: firstname,
      lastname: lastname,
      username: username,
      designation: designation,
      avatar: avatarUrl,
      language: language,
    }
    axiosInstance({
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/api/users`,
      data: data,
    })
      .then(() => {
        setOpen(true)
        auth.saveUser({
          ...auth.account,
          avatar: avatarUrl,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleEditEducationModal = (e) => {
    setEditEducationModal(e)
    setSchoolErrMessage(false)
    setEducationId(0)
    setSelectedInstitution({ name: "Select item" })
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <>
      {savedData.searchResult === "" ? (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div className="sm:rounded-lg bg-white shadow -mx-4">
              {/* Replace your content */}
              <form
                className="divide-y divide-gray-200 lg:col-span-9"
                onSubmit={updateProfile}
                encType="multipart/form-data"
              >
                {/* Profile section */}
                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                  <div>
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                      {t("profile.profile")}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      {t("profile.description")}
                    </p>
                  </div>

                  <div className="mt-6 flex flex-col lg:flex-row">
                    <div className="flex-grow space-y-6">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 sm:col-span-6">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("profile.firstName")}
                          </label>
                          <input
                            type="text"
                            id="first-name"
                            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                            defaultValue={user.firstname}
                            onChange={(e) => {
                              setFirstname(e.target.value)
                            }}
                          />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("profile.lastName")}
                          </label>
                          <input
                            type="text"
                            id="last-name"
                            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                            defaultValue={user.lastname}
                            onChange={(e) => {
                              setLastname(e.target.value)
                            }}
                          />
                        </div>
                      </div>

                      <div className="mt-6 grid grid-cols-12 gap-6">
                        <div className="col-span-12 sm:col-span-6">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("profile.email")}
                          </label>
                          <input
                            type="text"
                            id="email"
                            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                            defaultValue={user.email}
                            disabled
                          />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t("profile.userName")}
                          </label>
                          <input
                            type="text"
                            id="username"
                            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                            defaultValue={user.username}
                            onChange={(e) => {
                              setUsername(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-shrink-0 lg:flex-grow-0">
                      <p
                        className="text-sm font-medium text-gray-700"
                        aria-hidden="true"
                      >
                        Photo
                      </p>
                      <div className="mt-1 lg:hidden">
                        <div className="flex items-center">
                          <div
                            className="inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-full"
                            aria-hidden="true"
                          >
                            {avatarUrl ? (
                              <img
                                className="h-full w-full rounded-full"
                                src={avatarUrl}
                                alt="Avatar"
                              />
                            ) : (
                              <span
                                className={classNames(
                                  "h-full w-full rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400"
                                )}
                              >
                                <UserIcon
                                  className="text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </div>
                          <div className="ml-5 rounded-md shadow-sm">
                            <div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
                              <label
                                htmlFor="mobile-user-photo"
                                className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700"
                              >
                                <span>Change</span>
                                <span className="sr-only"> user photo</span>
                              </label>
                              <input
                                id="mobile-user-photo"
                                type="file"
                                accept="image/*"
                                className="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                                onChange={(e) => {
                                  if (e.target.files && e.target.files[0]) {
                                    const formData = new FormData()
                                    formData.append("avatar", e.target.files[0])
                                    setLoading(true)
                                    axiosInstance({
                                      method: "post",
                                      url: `${process.env.REACT_APP_BASE_URL}/api/users/update_avatar`,
                                      data: formData,
                                    })
                                      .then((res) => {
                                        // setAvatarUrl(`${process.env.REACT_APP_BASE_URL}/${res.data.path}`) // display file from local
                                        setAvatarUrl(`${res.data.location}`) // display file from DO
                                      })
                                      .catch((err) => {
                                        console.log(err)
                                        setAvatarUrl("")
                                      })
                                      .finally(() => {
                                        setLoading(false)
                                        e.target.value = null
                                      })
                                  } else setAvatarUrl("")
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="relative hidden overflow-hidden rounded-full lg:block">
                        {avatarUrl ? (
                          <img
                            className="rounded-full h-32 w-32"
                            src={avatarUrl}
                            alt="Avatar"
                          />
                        ) : (
                          <span
                            className={classNames(
                              "h-32 w-32 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400"
                            )}
                          >
                            <UserIcon
                              className="h-28 w-28 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                        <label
                          htmlFor="desktop-user-photo"
                          className="absolute inset-0 flex h-32 w-32 items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 hover:opacity-100"
                        >
                          <span>Change</span>
                          <span className="sr-only"> user photo</span>
                          <input
                            id="desktop-user-photo"
                            type="file"
                            accept="image/*"
                            className="absolute inset-0 h-32 w-32 cursor-pointer rounded-md border-gray-300 opacity-0"
                            onChange={(e) => {
                              if (e.target.files && e.target.files[0]) {
                                setFileName(e.target.files[0].name)
                                setMimeType(e.target.files[0].type)
                                const reader = new FileReader()
                                reader.readAsDataURL(e.target.files[0])
                                reader.onload = function () {
                                  setImage(reader.result)
                                  setCropModalOpen(true)
                                }
                                e.target.value = null
                              } else setAvatarUrl("")
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 grid grid-cols-12 gap-6">
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="designation"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("profile.designation")}
                      </label>
                      <input
                        type="text"
                        id="designation"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                        defaultValue={user.designation}
                        onChange={(e) => {
                          setDesignation(e.target.value)
                        }}
                      />
                    </div>

                    <div className="col-span-12 sm:col-span-6">
                      <Listbox defaultValue={language} onChange={setLanguage}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                              {t("profile.language")}
                            </Listbox.Label>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm">
                                <span className="block truncate">
                                  {language}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {languages.map((lang, index) => (
                                    <Listbox.Option
                                      key={index}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-sky-600"
                                            : "text-gray-900",
                                          "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={lang}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              lang === language
                                                ? "font-semibold"
                                                : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {lang}
                                          </span>

                                          {lang === language ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-sky-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>

                  <div className="mt-6 grid grid-cols-12 gap-6">
                    <div className="col-span-12 lg:col-span-6">
                      <label
                        htmlFor="designation"
                        className="flex items-center text-sm font-medium text-gray-700"
                      >
                        {t("sidebar.institution")}&nbsp;&nbsp;&nbsp;
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setOpenEducationModal(true)
                          }}
                        >
                          <PlusCircleIcon
                            className="h-6 w-6 text-sky-600 hover:text-sky-700"
                            aria-hidden="true"
                          />
                        </button>
                      </label>
                      {schools.length > 0 &&
                        schools.map((school) => (
                          <div
                            className="mt-3 flex justify-between items-center"
                            key={school.id}
                          >
                            <div>
                              <p className="text-sm font-medium text-gray-700">
                                {school.institution.name}
                              </p>
                              <p className="text-sm text-gray-500">
                                {school.date_start !== null ? (
                                  <>
                                    {new Date(school.date_start)
                                      .toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                      })
                                      .split("/")
                                      .reverse()
                                      .join("-")}{" "}
                                    to{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                                {school.date_end !== null
                                  ? new Date(school.date_end)
                                      .toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                      })
                                      .split("/")
                                      .reverse()
                                      .join("-")
                                  : ""}
                              </p>
                            </div>
                            <div className="flex">
                              <button
                                className="text-sky-600 hover:text-sky-900"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setEducationId(school.id)
                                }}
                              >
                                <PencilSquareIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                className="text-red-600 hover:text-red-900 pl-3"
                                onClick={(e) => {
                                  e.preventDefault()
                                  axiosInstance({
                                    method: "delete",
                                    url: `${process.env.REACT_APP_BASE_URL}//institutions`,
                                    data: {
                                      id: school.id,
                                      user_id: id,
                                    },
                                  })
                                    .then((res) => {
                                      setSchools(res.data)
                                    })
                                    .catch((err) => {
                                      console.log(err)
                                    })
                                }}
                              >
                                <TrashIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="divide-y divide-gray-200">
                  <div className="flex justify-end py-3 px-4 sm:px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                      onClick={() => navigate("/dashboard")}
                    >
                      {t("button.back")}
                    </button>
                    <button className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                      {t("button.save")}
                    </button>
                  </div>
                </div>
              </form>
              {/* End replace */}
              {/* crop modal */}
              <Transition.Root show={cropModalOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  initialFocus={cancelButtonRef}
                  onClose={setCropModalOpen}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                          <div>
                            <div className="mt-3 text-center sm:mt-5">
                              <div className="cropper">
                                <div className="relative h-[250px]">
                                  <Cropper
                                    image={image}
                                    aspect={aspectRatio}
                                    crop={crop}
                                    zoom={zoom}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    style={{
                                      containerStyle: {
                                        width: "100%",
                                        height: "80%",
                                        backgroundColor: "#fff",
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                              onClick={() => {
                                onCropDone(croppedArea)
                                setCropModalOpen(false)
                              }}
                            >
                              Done
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                onCropCancel()
                                setCropModalOpen(false)
                              }}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
              {/* end crop modal */}
              {/* Save success modal */}
              <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                          <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                              <CheckIcon
                                className="h-6 w-6 text-green-600"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                              >
                                {t("modal.updatedSuccessfulTitle")}
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  {t("modal.profileUpdatedContent")}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:text-sm"
                              onClick={() => {
                                setOpen(false)
                                navigate("/dashboard")
                              }}
                            >
                              {t("modal.backToDashboard")}
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
              {/* End Save success modal */}
              {/* Create Education modal */}
              <Transition.Root show={openEducationModal} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={setOpenEducationModal}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                          <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                              onClick={() => {
                                setSchoolErrMessage(false)
                                setOpenEducationModal(false)
                              }}
                            >
                              <span className="sr-only">Close</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                          <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                              <Dialog.Title
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900"
                              >
                                {t("button.add")} {t("profile.institution")}
                              </Dialog.Title>
                              <div className="mt-2">
                                <Listbox
                                  value={selectedInstitution}
                                  onChange={setSelectedInstitution}
                                >
                                  {({ open }) => (
                                    <>
                                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                        {t("profile.institution")}
                                      </Listbox.Label>
                                      <div className="relative mt-1">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6">
                                          <span className="block truncate">
                                            {selectedInstitution.name}
                                          </span>
                                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon
                                              className="h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </Listbox.Button>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          leave="transition ease-in duration-100"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {institutions.map((institution) => (
                                              <Listbox.Option
                                                key={institution.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active
                                                      ? "bg-sky-600 text-white"
                                                      : "text-gray-900",
                                                    institution.id === 0 &&
                                                      "border-b-2",
                                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                                  )
                                                }
                                                value={institution}
                                              >
                                                {({ selected, active }) => (
                                                  <>
                                                    <span
                                                      className={classNames(
                                                        selected
                                                          ? "font-semibold"
                                                          : "font-normal",
                                                        "block truncate"
                                                      )}
                                                    >
                                                      {institution.name}
                                                    </span>

                                                    {selected ? (
                                                      <span
                                                        className={classNames(
                                                          active
                                                            ? "text-white"
                                                            : "text-sky-600",
                                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                      >
                                                        <CheckIcon
                                                          className="h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            ))}
                                          </Listbox.Options>
                                        </Transition>
                                      </div>
                                    </>
                                  )}
                                </Listbox>
                                {schoolErrMessage && (
                                  <span className="text-sm text-red-600">
                                    {t("form.required")}
                                  </span>
                                )}
                              </div>
                              <div className="mt-2">
                                <label className="block text-sm font-medium text-gray-700">
                                  {t("profile.startDate")}
                                </label>
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  dateFormat="yyyy-MM"
                                  placeholderText="YYYY-MM"
                                  showMonthYearPicker
                                  required
                                />
                              </div>
                              <div className="mt-2">
                                <label className="block text-sm font-medium text-gray-700">
                                  {t("profile.endDate")}
                                </label>
                                <DatePicker
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                  dateFormat="yyyy-MM"
                                  placeholderText="YYYY-MM"
                                  showMonthYearPicker
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 sm:ml-3 sm:w-auto"
                              onClick={() => {
                                if (
                                  selectedInstitution.name === "Select item"
                                ) {
                                  setSchoolErrMessage(true)
                                } else {
                                  const data = {
                                    institution: selectedInstitution,
                                    user_id: id,
                                    start_date: startDate,
                                    end_date: endDate,
                                  }
                                  axiosInstance({
                                    method: "post",
                                    url: `${process.env.REACT_APP_BASE_URL}/api/users/institutions`,
                                    data: data,
                                  })
                                    .then((res) => {
                                      setSchools(res.data)
                                      setSchoolErrMessage(false)
                                      setOpenEducationModal(false)
                                      setSelectedInstitution({
                                        name: "Select item",
                                      })
                                      setStartDate(null)
                                      setEndDate(null)
                                    })
                                    .catch((err) => {
                                      console.log(err)
                                    })
                                }
                              }}
                            >
                              {t("button.save")}
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                              onClick={() => {
                                setSchoolErrMessage(false)
                                setOpenEducationModal(false)
                              }}
                            >
                              {t("button.cancel")}
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
              {/* End Create Education modal */}
              {/* Edit Education modal */}
              <Transition.Root show={editEducationModal} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={handleEditEducationModal}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                          <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                              onClick={() => {
                                setEducationId(0)
                                setEditEducationModal(false)
                                setSchoolErrMessage(false)
                                setSelectedInstitution({
                                  name: "Select item",
                                })
                                setStartDate(null)
                                setEndDate(null)
                              }}
                            >
                              <span className="sr-only">Close</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                          <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                              <Dialog.Title
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900"
                              >
                                {t("button.edit")} {t("profile.institution")}
                              </Dialog.Title>
                              <div className="mt-2">
                                <Listbox
                                  value={selectedInstitution}
                                  onChange={setSelectedInstitution}
                                >
                                  {({ open }) => (
                                    <>
                                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                        {t("profile.institution")}
                                      </Listbox.Label>
                                      <div className="relative mt-1">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6">
                                          <span className="block truncate">
                                            {selectedInstitution.name}
                                          </span>
                                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon
                                              className="h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </Listbox.Button>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          leave="transition ease-in duration-100"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {institutions.map((institution) => (
                                              <Listbox.Option
                                                key={institution.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active
                                                      ? "bg-sky-600 text-white"
                                                      : "text-gray-900",
                                                    institution.id === 0 &&
                                                      "border-b-2",
                                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                                  )
                                                }
                                                value={institution}
                                              >
                                                {({ selected, active }) => (
                                                  <>
                                                    <span
                                                      className={classNames(
                                                        selected
                                                          ? "font-semibold"
                                                          : "font-normal",
                                                        "block truncate"
                                                      )}
                                                    >
                                                      {institution.name}
                                                    </span>

                                                    {selected ? (
                                                      <span
                                                        className={classNames(
                                                          active
                                                            ? "text-white"
                                                            : "text-sky-600",
                                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                      >
                                                        <CheckIcon
                                                          className="h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            ))}
                                          </Listbox.Options>
                                        </Transition>
                                      </div>
                                    </>
                                  )}
                                </Listbox>
                                {schoolErrMessage && (
                                  <span className="text-sm text-red-600">
                                    {t("form.required")}
                                  </span>
                                )}
                              </div>
                              <div className="mt-2">
                                <label className="block text-sm font-medium text-gray-700">
                                  {t("profile.startDate")}
                                </label>
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  dateFormat="yyyy-MM"
                                  placeholderText="YYYY-MM"
                                  showMonthYearPicker
                                  required
                                />
                              </div>
                              <div className="mt-2">
                                <label className="block text-sm font-medium text-gray-700">
                                  {t("profile.endDate")}
                                </label>
                                <DatePicker
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                  dateFormat="yyyy-MM"
                                  placeholderText="YYYY-MM"
                                  showMonthYearPicker
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 sm:ml-3 sm:w-auto"
                              onClick={() => {
                                if (
                                  selectedInstitution.name === "Select item"
                                ) {
                                  setSchoolErrMessage(true)
                                } else {
                                  const data = {
                                    institution: selectedInstitution,
                                    id: educationId,
                                    user_id: id,
                                    start_date: startDate,
                                    end_date: endDate,
                                  }
                                  axiosInstance({
                                    method: "patch",
                                    url: `${process.env.REACT_APP_BASE_URL}/api/users/institutions`,
                                    data: data,
                                  })
                                    .then((res) => {
                                      setSchools(res.data)
                                      setEducationId(0)
                                      setSelectedInstitution({
                                        name: "Select item",
                                      })
                                      setStartDate(null)
                                      setEndDate(null)
                                      setEditEducationModal(false)
                                      setSchoolErrMessage(false)
                                    })
                                    .catch((err) => {
                                      console.log(err)
                                    })
                                }
                              }}
                            >
                              {t("button.save")}
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                              onClick={() => {
                                setEducationId(0)
                                setSelectedInstitution({
                                  name: "Select item",
                                })
                                setStartDate(null)
                                setEndDate(null)
                                setEditEducationModal(false)
                                setSchoolErrMessage(false)
                              }}
                            >
                              {t("button.cancel")}
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
              {/* End Edit Education modal */}
              {/* Spinner */}
              {loading && (
                <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100 flex items-center justify-center">
                  <RingLoader
                    color="#4f46e5"
                    loading={loading}
                    cssOverride={override}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              )}
              {/* End Spinner */}
            </div>
          </div>
        </div>
      ) : (
        <GlobalSearch />
      )}
    </>
  )
}
