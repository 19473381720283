import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { UserIcon } from "@heroicons/react/20/solid"
import axiosInstance from "../../utils/axios"
import { useContent } from "../../context/useContent"
import { GlobalSearch } from "../GlobalSearch"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Dashboard = () => {
  const { t } = useTranslation("translation")
  const id = localStorage.getItem("id")
  const savedData = useContent()
  const [currentHour, setCurrentHour] = useState(0)
  const [user, setUser] = useState({})
  const [greeting, setGreeting] = useState("")
  const [cases, setCases] = useState([])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/cases/all`,
      params: {
        userId: id,
      },
    })
      .then((res) => {
        setCases(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])

  useEffect(() => {
    setCurrentHour(new Date().getHours())
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/userId/${id}`,
    })
      .then((res) => {
        setUser(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])

  useEffect(() => {
    switch (true) {
      case currentHour < 12:
        setGreeting("goodMorning")
        break
      case currentHour > 12 && currentHour < 17:
        setGreeting("goodAfternoon")
        break
      default:
        setGreeting("goodEvening")
        break
    }
  }, [currentHour])
  return (
    <>
      {savedData.searchResult === "" ? (
        <>
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <h2 className="sr-only" id="profile-overview-title">
                Profile Overview
              </h2>
              <div className="bg-white p-6">
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="sm:flex sm:space-x-5">
                    <div className="flex-shrink-0">
                      {user.avatar ? (
                        <img
                          className="mx-auto h-20 w-20 rounded-full"
                          src={user.avatar}
                          alt="Avatar"
                        />
                      ) : (
                        <span
                          className={classNames(
                            "h-20 w-20 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400"
                          )}>
                          <UserIcon
                            className="h-12 w-12 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                    </div>
                    <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p className="text-sm font-medium text-gray-600">
                        {t(`dashboard.${greeting}`)},
                      </p>
                      {user.firstname ? (
                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                          {user.firstname} {user.lastname}
                        </p>
                      ) : (
                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                          {user.username}
                        </p>
                      )}
                      <p className="text-sm font-medium text-gray-600">
                        {user.designations}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 flex justify-center sm:mt-0">
                    <Link
                      to="/profile"
                      className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50">
                      {t("dashboard.editProfile")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:divide-y-0 sm:divide-x">
                <div className="px-6 py-5 text-center text-sm font-medium">
                  <span className="text-gray-900">{cases.length}</span>{" "}
                  {cases.length > 1 ? (
                    <span className="text-gray-600">
                      {t("sidebar.mdlogbook")} {t("mdlogbook.cases")}
                    </span>
                  ) : (
                    <span className="text-gray-600">
                      {t("sidebar.mdlogbook")} {t("mdlogbook.case")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded-lg bg-white shadow py-8">
                <div className="relative px-6 lg:px-8">
                  <div className="mx-auto max-w-prose text-lg">
                    <h1>
                      <span className="block text-center text-lg font-semibold text-sky-600">
                        Introducing
                      </span>
                      <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        {t("sidebar.mdlogbook")}
                      </span>
                    </h1>
                    <p className="mt-8 text-xl leading-8 text-gray-500">
                      MDLogBook is the first procedure logbook designed for
                      national use across Canada. It is specifically built for
                      Cardiologists and allows them to easily log their
                      procedures and review cases. This innovative tool is a
                      valuable resource for medical professionals, helping them
                      to track their work and improve patient care. Whether you
                      are a seasoned medical professional or just starting out
                      in your career, MDLogBook is an essential tool for
                      managing your practice and staying organized.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded-lg bg-white shadow py-8">
                <div className="relative px-6 lg:px-8">
                  <div className="mx-auto max-w-prose text-lg">
                    <h1>
                      <span className="block text-center text-lg font-semibold text-sky-600">
                        Contact us
                      </span>
                    </h1>
                    <p className="mt-8 text-xl leading-8 text-gray-500">
                      If you have questions for this project, please send{" "}
                      <a href="mailto:cardio@me.com" className="text-blue-600">
                        cardio@me.com
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <GlobalSearch />
      )}
    </>
  )
}
