import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import DatePicker from "react-datepicker"
import axiosInstance from "../../utils/axios"

export const ViewCase = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const params = useParams()

  const [procedure, setProcedure] = useState("")
  const [date, setDate] = useState(new Date())
  const [location, setLocation] = useState("")
  const [no, setNo] = useState("")
  const [dob, setDob] = useState(new Date())
  const [age, setAge] = useState(0)
  const [gender, setGender] = useState("male")
  const [diagnosis, setDiagnosis] = useState("")
  const [complication, setComplication] = useState("")
  const [outcome, setOutcome] = useState("success")
  const [duration, setDuration] = useState(0)

  // calculate age
  const calculate_age = (dob) => {
    const diff_ms = Date.now() - dob.getTime()
    const age_dt = new Date(diff_ms)
    return Math.abs(age_dt.getUTCFullYear() - 1970)
  }

  useEffect(() => {
    if (params.id) {
      axiosInstance({
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/case/handle`,
        params: {
          id: params.id,
        },
      })
        .then((res) => {
          setProcedure(res.data.case.mdlogbookcase.medicalprocedure.title)
          setDate(new Date(res.data.case.mdlogbookcase.date))
          setLocation(res.data.case.mdlogbookcase.location)
          setNo(res.data.case.mdlogbookcase.no)
          setDob(new Date(res.data.case.mdlogbookcase.dob))
          setAge(res.data.case.mdlogbookcase.age)
          setGender(res.data.case.mdlogbookcase.gender)
          setDiagnosis(res.data.case.mdlogbookcase.diagnosis)
          setComplication(res.data.case.mdlogbookcase.complication)
          setOutcome(res.data.case.mdlogbookcase.outcome)
          setDuration(res.data.case.mdlogbookcase.duration)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [params.id])
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
        <span className="text-2xl font-semibold text-gray-900">
          {procedure}
        </span>
        <div className="md:mt-0 mt-4">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            onClick={() => {
              navigate("/mdlogbook/review")
            }}
          >
            {t("button.back")}
          </button>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="md:grid md:grid-cols-4 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t("mdlogbook.information")}
                </h3>
              </div>
            </div>
            <div className="mt-5 md:col-span-3 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md sm:mx-0 -mx-4">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="col-span-6">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t("mdlogbook.date")}
                    </label>
                    <DatePicker
                      selected={date}
                      onChange={(date) => setDate(date)}
                      disabled={true}
                    />
                  </div>

                  <div className="col-span-6">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("mdlogbook.location")}
                    </label>
                    <input
                      type="text"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                      value={location}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("mdlogbook.patient")}
                  </h3>
                </div>
              </div>
              <div className="mt-5 md:col-span-3 md:mt-0">
                <div className="overflow-hidden shadow sm:rounded-md sm:mx-0 -mx-4">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.medicalRecordNumber")}
                        </label>
                        <input
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                          value={no}
                          disabled
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          DOB
                        </label>
                        <DatePicker
                          selected={dob}
                          onChange={(date) => {
                            setDob(date)
                            setAge(calculate_age(new Date(date)))
                          }}
                          placeholderText="YYYY/MM/DD"
                          dateFormat="yyyy-MM-dd"
                          disabled
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.age")}
                        </label>
                        <p className="mt-3">{age}</p>
                      </div>
                    </div>
                    <div className="space-y-6 bg-white py-5 sm:py-6">
                      <fieldset>
                        <legend className="contents text-base font-medium text-gray-900">
                          {t("mdlogbook.gender")}
                        </legend>
                        <div className="mt-4 flex items-center">
                          <div className="flex items-center pr-4">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="Male"
                              checked={gender === "Male"}
                              disabled
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.male")}
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="Female"
                              checked={gender === "Female"}
                              disabled
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.female")}
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("mdlogbook.details")}
                  </h3>
                </div>
              </div>
              <div className="mt-5 md:col-span-3 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md sm:mx-0 -mx-4">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        {t("mdlogbook.diagnosis-description")}
                      </label>
                      <div className="mt-1">
                        <textarea
                          rows={3}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                          value={diagnosis}
                          disabled
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        {t("Complication")}
                      </label>
                      <div className="mt-1">
                        <textarea
                          rows={3}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                          value={complication}
                          disabled
                        />
                      </div>
                    </div>
                    <fieldset>
                      <legend className="contents text-base font-medium text-gray-900">
                        {t("mdlogbook.outcome")}
                      </legend>
                      <div className="mt-4 flex items-center">
                        <div className="flex items-center pr-4">
                          <input
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                            value="success"
                            checked={outcome === "success"}
                            disabled
                          />
                          <label className="ml-3 block text-sm font-medium text-gray-700">
                            {t("mdlogbook.success")}
                          </label>
                        </div>
                        <div className="flex items-center pr-4">
                          <input
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                            value="failure"
                            checked={outcome === "failure"}
                            disabled
                          />
                          <label className="ml-3 block text-sm font-medium text-gray-700">
                            {t("mdlogbook.failure")}
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                            value="notApplicable"
                            checked={outcome === "notApplicable"}
                            disabled
                          />
                          <label className="ml-3 block text-sm font-medium text-gray-700">
                            {t("mdlogbook.notApplicable")}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        {t("mdlogbook.duration")} (mins)
                      </label>
                      <input
                        type="number"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                        value={duration}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
