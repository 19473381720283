import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import DatePicker from "react-datepicker"
import { CSVLink } from "react-csv"
import axiosInstance from "../../../utils/axios"
import "./index.css"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Stat = () => {
  const { t } = useTranslation("translation")
  const [users, setUsers] = useState([])
  const [cases, setCases] = useState([])
  const [procedures, setProcedures] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [backendData, setBackendData] = useState([])
  const [open, setOpen] = useState(false)
  const csvLinkRef = useRef(null)
  const [result, setResult] = useState([])
  const [optionValue, setOptionValue] = useState("all")
  const [showDateRange, setShowDateRange] = useState(false)

  const headers = [
    { label: "CREATOR", key: "creator" },
    { label: "TITLE", key: "title" },
    { label: "AGE", key: "age" },
    { label: "DATE", key: "date" },
    { label: "COMPLICATION", key: "complication" },
    { label: "DIAGNOSIS_AND_DESCRIPTION", key: "diagnosis" },
    { label: "DOB", key: "dob" },
    { label: "DURATION_MINS", key: "duration" },
    { label: "SEX", key: "gender" },
    { label: "LOCATION", key: "location" },
    { label: "MRN", key: "no" },
    { label: "OUTCOME", key: "outcome" },
    { label: "TAGS", key: "tags" },
  ]

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/all`,
    })
      .then((res) => {
        setUsers(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/total-cases`,
    })
      .then((res) => {
        setCases(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/medical-procedures/all-count`,
    })
      .then((res) => {
        setProcedures(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    optionValue === "date" ? setShowDateRange(true) : setShowDateRange(false)
  }, [optionValue])

  const handleDownload = () => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/stats/download`,
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    })
      .then((res) => {
        setBackendData(res.data)
        setOpen(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (open === true && csvLinkRef.current) {
      csvLinkRef.current.link.click()
    }
  }, [open])

  useEffect(() => {
    if (backendData.length > 0) {
      let temp = []
      backendData.forEach((element) => {
        if (element.user !== null) {
          let tagArray = ""
          if (element.tags.length > 0) {
            element.tags.forEach((item, index) => {
              if (index === element.tags.length - 1) {
                tagArray += item.name
              } else {
                tagArray += item.name + ", "
              }
            })
          } else {
            tagArray = "None"
          }
          const obj = {
            creator: element.user.firstname + " " + element.user.lastname,
            title: element.medicalprocedure.title,
            age: element.age,
            complication: element.complication,
            diagnosis: element.diagnosis,
            dob: element.dob,
            duration: element.duration,
            gender: element.gender,
            location: element.location,
            no: element.no,
            outcome: element.outcome,
            date: element.date,
            tags: tagArray,
          }
          temp.push(obj)
        } else return
      })
      setResult(temp)
    }
  }, [backendData])

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <span className="text-2xl font-semibold text-gray-900 lg:mb-0 mb-4">
          {t("sidebar.stats")}
        </span>
        <div className="lg:flex lg:space-y-0 space-y-2 lg:mt-0 mt-4 items-center">
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className={classNames(
                optionValue === "all"
                  ? "bg-gray-100"
                  : "bg-white hover:bg-gray-50",
                "relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
              )}
              onClick={() => {
                setOptionValue("all")
                setStartDate("")
                setEndDate("")
              }}
            >
              {t("homepage.all")}
            </button>
            <button
              type="button"
              className={classNames(
                optionValue === "date"
                  ? "bg-gray-100"
                  : "bg-white hover:bg-gray-50",
                "relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
              )}
              onClick={() => {
                setOptionValue("date")
              }}
            >
              {t("stat.range")}
            </button>
          </span>
          {showDateRange ? (
            <div className="flex items-center lg:ml-2 enabled-range">
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date)
                }}
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
              />
              <label className="mx-2">{t("stat.to")}</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date)
                }}
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
              />
            </div>
          ) : (
            <div className="flex items-center lg:ml-2 disabled-range">
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date)
                }}
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
                disabled={true}
              />
              <label className="mx-2">{t("stat.to")}</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date)
                }}
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
                disabled={true}
              />
            </div>
          )}
          <button
            type="button"
            className={classNames(
              optionValue === "date" && startDate === "" && endDate === ""
                ? "opacity-50 cursor-default"
                : "hover:bg-sky-700 focus:ring-sky-500 focus:outline-none focus:ring-2 focus:ring-offset-2",
              "inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm lg:ml-2"
            )}
            disabled={
              optionValue === "date" && startDate === "" && endDate === ""
            }
            onClick={handleDownload}
          >
            {t("button.download")}
          </button>
          <CSVLink
            className="hidden"
            ref={csvLinkRef}
            headers={headers}
            data={result}
            filename="mdlogbook_stats.csv"
            onClick={() => setOpen(false)}
          >
            {t("button.download")}
          </CSVLink>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="py-4">
          <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">
                  {t("sidebar.user")}
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {users.length}
                </dd>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">
                  {t("mdlogbook.cases")}
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {cases.length}
                </dd>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">
                  {t("sidebar.procedure")}
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {procedures.length}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  )
}
