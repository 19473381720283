import React, { useState, useEffect, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar, Pie } from "react-chartjs-2"
import { CSVLink } from "react-csv"
import axiosInstance from "../../utils/axios"
import { useAuth } from "../../context/useAuth"
import { GlobalSearch } from "../GlobalSearch"
import { useContent } from "../../context/useContent"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export const LogSummary = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const userid = localStorage.getItem("id")
  const [open, setOpen] = useState(false) // for share modal open
  const auth = useAuth()
  const savedData = useContent()
  const [user, setUser] = useState({})

  const [tabs, setTabs] = useState([
    { name: "1 Week", href: "#", current: true, value: 6 },
    { name: "1 Month", href: "#", current: false, value: 29 },
    { name: "3 Months", href: "#", current: false, value: 93 },
    { name: "6 Months", href: "#", current: false, value: 185 },
    { name: "1 Year", href: "#", current: false, value: 365 },
    { name: "All", href: "#", current: false, value: "all" },
  ])
  const [duration, setDuration] = useState(6)
  const [entries, setEntries] = useState(0)
  const [labels, setLabels] = useState([])
  const [data, setData] = useState({})
  const [result, setResult] = useState([])
  const [countData, setCountData] = useState([])
  const [printData, setPrintData] = useState("")
  const currentDate = new Date().toISOString().slice(0, 10)
  // pieChart const init section
  const [pieLabel, setPieLabel] = useState([])
  const [pieData, setPieData] = useState({})
  const [pieGraphData, setPieGraphData] = useState([])
  // end pieChart const init section
  // csv export init section
  const [startedDate, setStartedDate] = useState("")
  const [summaryDataContent, setSummaryDataContent] = useState([])
  const summaryDataHeader = [
    { label: "PROCEDURE", key: "title" },
    { label: "COUNT", key: "number" },
  ]
  const [fullDataContent, setFullDataContent] = useState([])
  const fullDataHeader = [
    { label: "PROCEDURE", key: "procedure" },
    { label: "GROUP", key: "group" },
    { label: "DATE", key: "date" },
    { label: "LOCATION", key: "location" },
    { label: "MRN", key: "mrn" },
    { label: "DOB", key: "dob" },
    { label: "AGE", key: "age" },
    { label: "SEX", key: "sex" },
    { label: "DIAGNOSIS_AND_DESCRIPTION", key: "diagnosisanddescription" },
    { label: "COMPLICATIONS", key: "complication" },
    { label: "OUTCOME", key: "outcome" },
    { label: "DURATION_MINS", key: "duration" },
  ]
  // end csv export init section

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1, // display only integer data in Y-axis
        },
      },
    },
  }

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/userId/${userid}`,
    })
      .then((res) => {
        setUser(res.data)
        auth.saveUser(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [userid])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/summary`,
      params: {
        userid: userid,
        duration: duration,
      },
    })
      .then((res) => {
        let chartLabel = []
        let pieChartLabel = []
        let pieChartData = [] // data value for piechart
        if (res.data.result.length > 0) {
          res.data.result.forEach((val) => {
            chartLabel.push(val.year.toString())
          })
        } else {
          chartLabel = ["2023"]
        }
        setStartedDate(res.data.startDate)
        setResult(res.data.result)
        setCountData(res.data.countingData)
        setLabels(chartLabel)
        setEntries(res.data.entries)
        res.data.countingData.forEach((item) => {
          pieChartLabel.push(item.group)
          pieChartData.push(item.groupCount)
        })
        setPieGraphData(pieChartData)
        setPieLabel(pieChartLabel)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [duration, userid])

  useEffect(() => {
    setData({
      labels,
      datasets: [
        {
          label: "Entries",
          data: result.map((item) => item.value.length),
          backgroundColor: "rgba(2, 132, 199, 0.5)",
        },
      ],
    })
  }, [labels, result])

  useEffect(() => {
    setPieData({
      labels: pieLabel,
      datasets: [
        {
          label: "Number of Cases",
          data: pieGraphData,
          backgroundColor: [
            "rgba(168, 85, 247, 0.2)",
            "rgba(14, 165, 233, 0.2)",
            "rgba(20, 184, 166, 0.2)",
            "rgba(132, 204, 22, 0.2)",
            "rgba(234, 179, 8, 0.2)",
            "rgba(249, 115, 22, 0.2)",
            "rgba(100, 116, 139, 0.2)",
            "rgba(239, 68, 68, 0.2)",
            "rgba(34, 197, 94, 0.2)",
            "rgba(99, 102, 241, 0.2)",
            "rgba(244, 63, 94, 0.2)",
            "rgba(217, 70, 239, 0.2)",
          ],
          borderColor: [
            "rgba(168, 85, 247, 1)",
            "rgba(14, 165, 233, 1)",
            "rgba(20, 184, 166, 1)",
            "rgba(132, 204, 22, 1)",
            "rgba(234, 179, 8, 1)",
            "rgba(249, 115, 22, 1)",
            "rgba(100, 116, 139, 1)",
            "rgba(239, 68, 68, 1)",
            "rgba(34, 197, 94, 1)",
            "rgba(99, 102, 241, 1)",
            "rgba(244, 63, 94, 1)",
            "rgba(217, 70, 239, 1)",
          ],
          borderWidth: 1,
        },
      ],
    })
  }, [pieLabel, pieGraphData])

  useEffect(() => {
    let printString = ""
    if (countData.length > 0) {
      const temp = []
      countData.forEach((data) => {
        const temp1 = []
        data.procedure.forEach((item) =>
          temp1.push(`<tr>
            <td style="border-bottom:1px solid #CDCDCD;">${item.title}</td>
            <td style="border-bottom:1px solid #CDCDCD;">${item.number}</td>
          </tr>`)
        )
        temp.push(
          `<tr style="background-color:#EEEEEE;">
              <th style="border-width:1px 0px 1px 0px; border-color:#7F7F7F; border-style:solid;">${
                data.group
              }</th>
              <th style="border-width:1px 0px 1px 0px; border-color:#7F7F7F; border-style:solid;">
                ${data.groupCount}
              </th>
            </tr>
            ${temp1.join("")}`
        )
      })
      if (duration === "all") {
        printString = `
        <html>
          <head><title>MDLogBook - MDLogBook</title></head>
          <body style="font-family:'Lucida Grande', Helvetica, Arial;">
            <h2>MDLogBook</h2>
            <h3>Summary</h3>
            <table cellpadding="6" cellspacing="0" border=0">
              <tbody>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Name:</td>
                  <td>${user.firstname} ${user.lastname}</td>
                <tr>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Entry Count:</td>
                  <td>${entries}</td>
                <tr>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Summary Period:</td>
                  <td>All</td>
                <tr>
              </tbody>
            </table>
            <h3>Totals</h3>
            <table cellpadding="6" cellspacing="0" border="0" style="border-bottom:1px solid #7F7F7F;">
              <tbody>
                ${temp.join("")}
              </tbody>
            </table>
          </body>
        </html>`
      } else {
        printString = `
        <html>
          <head><title>MDLogBook - MDLogBook</title></head>
          <body style="font-family:'Lucida Grande', Helvetica, Arial;">
            <h2>MDLogBook</h2>
            <h3>Summary</h3>
            <table cellpadding="6" cellspacing="0" border=0">
              <tbody>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Name:</td>
                  <td>${user.firstname} ${user.lastname}</td>
                <tr>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Entry Count:</td>
                  <td>${entries}</td>
                <tr>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Summary Period:</td>
                  <td>${startedDate} to ${
          new Date().toISOString().split("T")[0]
        }</td>
                <tr>
              </tbody>
            </table>
            <h3>Totals</h3>
            <table cellpadding="6" cellspacing="0" border="0" style="border-bottom:1px solid #7F7F7F;">
              <tbody>
                ${temp.join("")}
              </tbody>
            </table>
          </body>
        </html>`
      }
      setPrintData(printString)
    } else {
      if (duration === "all") {
        printString = `
        <html>
          <head><title>MDLogBook - MDLogBook</title></head>
          <body style="font-family:'Lucida Grande', Helvetica, Arial;">
            <h2>MDLogBook</h2>
            <h3>Summary</h3>
            <table cellpadding="6" cellspacing="0" border=0">
              <tbody>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Name:</td>
                  <td>${user.firstname} ${user.lastname}</td>
                <tr>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Entry Count:</td>
                  <td>${entries}</td>
                <tr>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Summary Period:</td>
                  <td>All</td>
                <tr>
              </tbody>
            </table>
          </body>
        </html>`
      } else {
        printString = `
        <html>
          <head><title>MDLogBook - MDLogBook</title></head>
          <body style="font-family:'Lucida Grande', Helvetica, Arial;">
            <h2>MDLogBook</h2>
            <h3>Summary</h3>
            <table cellpadding="6" cellspacing="0" border=0">
              <tbody>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Name:</td>
                  <td>${user.firstname} ${user.lastname}</td>
                <tr>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Entry Count:</td>
                  <td>${entries}</td>
                <tr>
                <tr>
                  <td style="text-align:right; font-weight:bold;">Summary Period:</td>
                  <td>${startedDate} to ${
          new Date().toISOString().split("T")[0]
        }</td>
                <tr>
              </tbody>
            </table>
          </body>
        </html>`
      }
      setPrintData(printString)
    }
  }, [user, entries, duration, countData, startedDate])

  useEffect(() => {
    let temp = []
    countData.forEach((data) => {
      data.procedure.forEach((item) => {
        temp.push(item)
      })
    })
    setSummaryDataContent(temp)
  }, [countData])

  useEffect(() => {
    let temp = []
    result.forEach((data) => {
      if (data.value.length > 0) {
        data.value.forEach((item) => {
          if (item.medicalprocedure !== null) {
            temp.push({
              procedure: item.medicalprocedure.title,
              group: item.medicalprocedure.medicalproceduregroup.title,
              date: new Date(item.date).toISOString().split("T")[0],
              location: item.location,
              mrn: item.no,
              dob: item.dob,
              age: item.age,
              sex: item.gender,
              diagnosisanddescription: item.diagnosis,
              complication: item.complication,
              outcome: item.outcome,
              duration: item.duration,
            })
          } else {
            return
          }
        })
      } else return
    })
    setFullDataContent(temp)
  }, [result])

  window.onresize = function () {
    window.location.reload()
  }

  return (
    <>
      {savedData.searchResult === "" ? (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
            <span className="text-2xl font-semibold text-gray-900">
              {t("mdlogbook.summary")}
            </span>
            <div className="md:mt-0 mt-4 flex items-center">
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 mr-2"
                onClick={() => setOpen(true)}
              >
                {t("button.share")}
              </button>
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                onClick={() => {
                  navigate("/mdlogbook")
                }}
              >
                {t("button.back")}
              </button>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-0 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <div className="py-4">
              <div>
                <div className="sm:hidden px-4">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-sky-500 focus:ring-sky-500"
                    defaultValue={tabs[0].name}
                    onChange={(e) => {
                      e.preventDefault()
                      tabs.forEach((tab) => {
                        if (tab.name === e.target.value) {
                          setDuration(tab.value)
                        }
                      })
                    }}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav
                    className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                    aria-label="Tabs"
                  >
                    {tabs.map((tab, tabIdx) => (
                      <a
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                          tab.current
                            ? "text-gray-900"
                            : "text-gray-500 hover:text-gray-700",
                          tabIdx === 0 ? "rounded-l-lg" : "",
                          tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                          "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                        onClick={(e) => {
                          e.preventDefault()
                          tabs.forEach((tab) => {
                            tab.current = false
                          })
                          setDuration(tab.value)
                          setTabs((_prev) => {
                            const tmp = [..._prev]
                            tmp[tabIdx].current = true
                            return tmp
                          })
                        }}
                      >
                        <span>{tab.name}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            tab.current ? "bg-sky-500" : "bg-transparent",
                            "absolute inset-x-0 bottom-0 h-0.5"
                          )}
                        />
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              {labels.length > 0 && Object.keys(data).length > 0 && (
                <div className="shadow sm:overflow-hidden rounded-md mt-5">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <Bar options={options} data={data} />
                  </div>
                </div>
              )}
              <div className="block" aria-hidden="true">
                <div className="py-3"></div>
              </div>
              {countData.length > 0 && (
                <div className="grid md:grid-cols-2 grid-cols-1 pb-5">
                  <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg max-h-[600px] bg-white">
                    <table className="min-w-full">
                      <thead></thead>
                      <tbody className="bg-white">
                        {countData.map((data, index) => (
                          <Fragment key={index}>
                            <tr className="border-t border-gray-200">
                              <th
                                scope="colgroup"
                                className="bg-gray-50 px-3 py-2 text-left text-sm font-semibold text-gray-900"
                              >
                                {data.group}
                              </th>
                              <th className="bg-gray-50 px-3 text-right">
                                {data.groupCount}
                              </th>
                            </tr>
                            {data.procedure.map((item, idx) => (
                              <tr
                                className="border-t border-gray-300"
                                key={idx}
                              >
                                <td className="whitespace-nowrap py-4 pl-3 pr-3 text-sm text-gray-500">
                                  {item.title}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                  {item.number}
                                </td>
                              </tr>
                            ))}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="sm:hidden block" aria-hidden="true">
                    <div className="py-5"></div>
                  </div>
                  <div className="shadow bg-white sm:overflow-auto sm:rounded-md md:ml-3 md:mt-0 sm:mt-6 mt-0 p-5">
                    <Pie data={pieData} />
                  </div>
                </div>
              )}
              <div className="sm:rounded-lg p-2 sm:p-3">
                <div className="flex flex-wrap items-center justify-center">
                  <p className="ml-3 truncate font-medium">
                    {result.length > 0 && (
                      <span className="inline">
                        {entries} {t("mdlogbook.entries")}, {startedDate} to{" "}
                        {currentDate}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            {/* /End replace */}
          </div>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                      <div>
                        <div className="text-center">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            {t("button.share")}
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:text-sm"
                          onClick={() => {
                            const newWindow = window.open("", "_blank")
                            newWindow.document.write(printData)
                            setOpen(false)
                          }}
                        >
                          {t("modal.printSummary")}
                        </button>
                      </div>
                      <div className="mt-2 sm:mt-3">
                        <CSVLink
                          data={summaryDataContent}
                          headers={summaryDataHeader}
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:text-sm"
                          filename={
                            user.username +
                            "_mdlogbook_summary_" +
                            startedDate +
                            "_" +
                            new Date().toISOString().split("T")[0] +
                            ".csv"
                          }
                          onClick={() => {
                            setOpen(false)
                          }}
                        >
                          {t("modal.sheetSummary")}
                        </CSVLink>
                      </div>
                      <div className="mt-2 sm:mt-3">
                        <CSVLink
                          data={fullDataContent}
                          headers={fullDataHeader}
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:text-sm"
                          filename={
                            user.username +
                            "_mdlogbook_fulldata_" +
                            startedDate +
                            "_" +
                            new Date().toISOString().split("T")[0] +
                            ".csv"
                          }
                          onClick={() => {
                            setOpen(false)
                          }}
                        >
                          {t("modal.sheetFullData")}
                        </CSVLink>
                      </div>
                      <div className="mt-2 sm:mt-3">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                          onClick={() => {
                            setOpen(false)
                          }}
                        >
                          {t("modal.close")}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      ) : (
        <GlobalSearch />
      )}
    </>
  )
}
