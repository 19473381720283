// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker-wrapper .react-datepicker__input-container [type="text"] {
  margin-top: 0px !important;
}

@media screen and (min-width: 320px) {
  .react-datepicker-wrapper {
    width: 125px !important;
  }
}

.disabled-range
  .react-datepicker-wrapper
  .react-datepicker__input-container
  [type="text"] {
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Stat/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF;;AAEA;;;;EAIE,YAAY;AACd","sourcesContent":[".react-datepicker-wrapper .react-datepicker__input-container [type=\"text\"] {\n  margin-top: 0px !important;\n}\n\n@media screen and (min-width: 320px) {\n  .react-datepicker-wrapper {\n    width: 125px !important;\n  }\n}\n\n.disabled-range\n  .react-datepicker-wrapper\n  .react-datepicker__input-container\n  [type=\"text\"] {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
