import React, { Fragment, useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Papa from "papaparse"
import { Menu, Transition, Dialog } from "@headlessui/react"
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  ClockIcon,
} from "@heroicons/react/20/solid"
import { ExclamationTriangleIcon, CheckIcon } from "@heroicons/react/24/outline"
import { RingLoader } from "react-spinners"
import ReactPaginate from "react-paginate"
import axiosInstance from "../../utils/axios"
import { MappingItem } from "../../components/Mapping"
import { Tooltip } from "../../components/Tooltip"
import { useContent } from "../../context/useContent"
import { GlobalSearch } from "../GlobalSearch"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const override = {
  borderColor: "#4f46e5",
  zIndex: 31,
}

export const LogCase = () => {
  const navigate = useNavigate()
  const { t } = useTranslation("translation")
  const savedData = useContent()

  // for remove modal
  const [open, setOpen] = useState(false)
  const cancelRemoveButtonRef = useRef(null)
  // end
  const cancelButtonRef = useRef(null) // step by step modal cancel ref
  // opening file modal
  const [openFileModal, setOpenFileModal] = useState(false)
  const cancelFildModalButtonRef = useRef(null)
  // end opening file modal
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [dropdownMenu, setDropdownMenu] = useState(`${t("homepage.all")}`)
  const [filterValue, setFilterValue] = useState("all")
  const [logBooks, setLogBooks] = useState([])
  const [id, setId] = useState(0)
  const [parsedData, setParsedData] = useState([])
  const [csvHeaders, setCsvHeaders] = useState([])
  const [kMapping, setKMapping] = useState({})
  const [resultHeader, setResultHeader] = useState([])
  const [resultBody, setResultBody] = useState([])
  const [loading, setLoading] = useState(false) // for spinner toggle
  const [filename, setFilename] = useState("")
  const [step, setStep] = useState(1)

  // pagination
  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 10
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(logBooks.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(logBooks.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, logBooks])
  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % logBooks.length
    setItemOffset(newOffset)
  }
  // end pagination

  const hiddenFileInput = useRef(null)
  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleNext = () => {
    const dbHeaderedData = parsedData.map((row) => {
      const tempRow = {}
      Object.keys(row).forEach((key) => {
        if (kMapping[key] !== "Ignore") {
          tempRow[kMapping[key]] = row[key]
        }
      })
      return tempRow
    })
    setResultBody(
      dbHeaderedData.filter(
        (item) =>
          Object.keys(item).length !== 1 && item[Object.keys(item)[0]] !== "\n"
      )
    )
    if (dbHeaderedData.length > 0) {
      const temp = Object.keys(dbHeaderedData[0])
      setResultHeader(temp.filter((item) => item !== "undefined"))
      setStep(3)
    }
  }

  const handleChange = (e) => {
    setFilename(e.target.files[0].name)
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const res = results.data.map((row) => {
          const keyArray = Object.keys(row)
          keyArray.forEach((item) => {
            row[item] = row[item].replace(/\r?\n|\r/g, "")
          })
          return row
        })
        setParsedData(res)
      },
    })
  }

  const handleColumnChange = (csvHeader, dbHeader) => {
    setKMapping((_prevMapping) => {
      const tempData = { ..._prevMapping }
      tempData[csvHeader] = dbHeader
      return tempData
    })
  }

  const userId = localStorage.getItem("id")
  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/cases/all`,
      params: {
        userId: userId,
      },
    })
      .then((res) => {
        setLogBooks(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [userId])

  useEffect(() => {
    switch (filterValue) {
      case "cardiology":
        axiosInstance({
          method: "get",
          url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/cases/cardiology`,
          params: {
            userId: userId,
          },
        })
          .then((res) => {
            setLogBooks(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case "respirology":
        axiosInstance({
          method: "get",
          url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/cases/respirology`,
          params: {
            userId: userId,
          },
        })
          .then((res) => {
            setLogBooks(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
        break
      default:
        axiosInstance({
          method: "get",
          url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/cases/all`,
          params: {
            userId: userId,
          },
        })
          .then((res) => {
            setLogBooks(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
        break
    }
  }, [filterValue, userId])

  return (
    <>
      {savedData.searchResult === "" ? (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
            <span className="text-2xl font-semibold text-gray-900">
              {t("mdlogbook.cases")}
            </span>
            <div className="md:mt-0 mt-4">
              <Menu as="div" className="relative inline-block text-left mr-2">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    {dropdownMenu}
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="absolute md:right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm border-b-2"
                            )}
                            onClick={() => {
                              setDropdownMenu(t("homepage.all"))
                              setFilterValue("all")
                            }}>
                            {t("homepage.all")}
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm"
                            )}
                            onClick={() => {
                              setDropdownMenu(t("homepage.cardiology"))
                              setFilterValue("cardiology")
                            }}>
                            {t("homepage.cardiology")}
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm"
                            )}
                            onClick={() => {
                              setDropdownMenu(t("homepage.respirology"))
                              setFilterValue("respirology")
                            }}>
                            {t("homepage.respirology")}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                className="hidden"
                accept=".csv"
              />
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 mr-2"
                onClick={() => setOpenFileModal(true)}>
                {t("mdlogbook.import")}
              </button>
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                onClick={() => {
                  navigate("/mdlogbook")
                }}>
                {t("button.back")}
              </button>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="my-6 flow-root md:hidden">
                    <ul className="-my-5 divide-y divide-gray-200 border-y bg-white">
                      {currentItems &&
                        currentItems.map((item) => (
                          <li key={item.id} className="py-4 px-4">
                            <div className="flex items-center space-x-3">
                              <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900 flex">
                                  {item.signed ? (
                                    <CheckIcon
                                      className="mr-1 h-5 w-5 text-green-600"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ClockIcon
                                      className="mr-1 h-5 w-5 text-gray-600"
                                      aria-hidden="true"
                                    />
                                  )}
                                  {item.medicalprocedure.title}
                                </p>
                                <p className="truncate text-sm text-gray-500">
                                  {item.age !== 0 && (
                                    <>
                                      {t("mdlogbook.age")} : {item.age}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                  )}
                                  {item.gender && (
                                    <>
                                      {t("mdlogbook.gender")} : {item.gender}
                                    </>
                                  )}
                                </p>
                                <p className="truncate text-sm text-gray-500">
                                  {t("mdlogbook.date")} :{" "}
                                  {new Date(item.date).toLocaleDateString()}
                                </p>
                                <p className="truncate text-sm text-gray-500">
                                  {t("mdlogbook.location")} : {item.location}
                                </p>
                              </div>
                              <div>
                                <button
                                  className="text-sky-600 hover:text-sky-900 px-2"
                                  onClick={() => {
                                    navigate(`/mdlogbook/cases/${item.id}`)
                                  }}>
                                  <MagnifyingGlassIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">, {item.id}</span>
                                </button>
                                <button
                                  className="text-red-600 hover:text-red-900"
                                  onClick={() => {
                                    setId(item.id)
                                    setOpen(true)
                                  }}>
                                  <TrashIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">, {item.id}</span>
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg hidden md:block">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            {t("mdlogbook.procedure")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            {t("mdlogbook.age")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            {t("mdlogbook.gender")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            {t("mdlogbook.date")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            {t("mdlogbook.location")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            {t("mdlogbook.reviewer")}
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {currentItems &&
                          currentItems.map((item) => (
                            <tr key={item.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <div className="flex">
                                  {item.signed ? (
                                    <Tooltip message="Signed-off">
                                      <CheckIcon
                                        className="mr-1 h-5 w-5 text-green-600"
                                        aria-hidden="true"
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip message="Pending sign-off">
                                      <ClockIcon
                                        className="mr-1 h-5 w-5 text-gray-600"
                                        aria-hidden="true"
                                      />
                                    </Tooltip>
                                  )}
                                  {item.medicalprocedure.title}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.age === 0 ? "" : item.age}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.gender}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(item.date).toLocaleDateString()}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.location}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.reviewers ? (
                                  item.reviewers.map((reviewer) => (
                                    <p key={reviewer.id}>
                                      {reviewer.firstname} {reviewer.lastname}
                                    </p>
                                  ))
                                ) : (
                                  <p>No reviewer</p>
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pr-6 text-right text-sm font-medium">
                                <div className="flex items-center justify-end">
                                  <button
                                    className="text-sky-600 hover:text-sky-900 px-2"
                                    onClick={() => {
                                      navigate(`/mdlogbook/cases/${item.id}`)
                                    }}>
                                    <MagnifyingGlassIcon
                                      className="-mr-1 ml-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                    <span className="sr-only">, {item.id}</span>
                                  </button>
                                  <button
                                    className="text-red-600 hover:text-red-900"
                                    onClick={() => {
                                      setId(item.id)
                                      setOpen(true)
                                    }}>
                                    <TrashIcon
                                      className="-mr-1 ml-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                    <span className="sr-only">, {item.id}</span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex justify-center mt-3">
                  <ReactPaginate
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="relative z-10 inline-flex items-center text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 border"
                    pageLinkClassName="px-4 py-2"
                    previousClassName="relative inline-flex items-center rounded-l-md text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    previousLinkClassName="px-2 py-2"
                    nextClassName="relative inline-flex items-center rounded-r-md text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    nextLinkClassName="px-2 py-2"
                    breakLabel="..."
                    breakClassName="relative inline-flex items-center text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    breakLinkClassName="px-4 py-2"
                    containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    activeClassName="bg-sky-600 text-white"
                    disabledClassName=""
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelRemoveButtonRef}
              onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ExclamationTriangleIcon
                              className="h-6 w-6 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900">
                              {t("modal.deleteCaseTitle")}
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                {t("modal.deleteCaseContent")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false)
                            axiosInstance({
                              method: "delete",
                              url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/delete`,
                              data: {
                                id: id,
                                userId: userId,
                              },
                            })
                              .then((res) => {
                                setLogBooks(res.data)
                              })
                              .catch((err) => {
                                console.log(err)
                              })
                          }}>
                          {t("modal.confirm")}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => setOpen(false)}
                          ref={cancelRemoveButtonRef}>
                          {t("modal.cancel")}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* opening modal step by step */}
          <Transition.Root show={openFileModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelFildModalButtonRef}
              onClose={setOpenFileModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* step1 === file opening modal */}
              {step === 1 && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div>
                          <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900">
                              {t("modal.csvFileImport")}
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-700 text-start font-bold">
                                Upload file
                              </p>
                              <div className="mt-2 flex rounded-md shadow-sm">
                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                  <input
                                    type="text"
                                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                    placeholder="CSV file URL"
                                    defaultValue={filename}
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 bg-sky-600 hover:bg-sky-500 focus-visible:outline-sky-600"
                                  onClick={handleClick}>
                                  Browse
                                </button>
                              </div>
                            </div>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                You can import cases in CSV format. Cases will
                                be matched by procedure name. Those that do not
                                match a known procedure name will be ignored.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="button"
                            className={classNames(
                              parsedData.length > 0
                                ? "bg-sky-600 hover:bg-sky-500 focus-visible:outline-sky-600"
                                : "bg-sky-300 cursor-default",
                              "inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2"
                            )}
                            onClick={() => {
                              if (parsedData.length > 0) {
                                setStep(2)
                                setCsvHeaders(Object.keys(parsedData[0]))
                              }
                            }}>
                            {t("button.next")}
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => setOpenFileModal(false)}
                            ref={cancelButtonRef}>
                            {t("button.cancel")}
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              )}

              {/* step2 === mapping modal */}
              {step === 2 && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                      <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900">
                            {t("modal.mapColumn")}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Now let's match the columns in your uploaded file
                              to your cases.
                            </p>
                          </div>
                          {csvHeaders.length > 0 &&
                            csvHeaders.map((item, index) => (
                              <MappingItem
                                item={item}
                                key={index}
                                onChange={handleColumnChange}
                              />
                            ))}
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                            onClick={handleNext}>
                            {t("button.next")}
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => setStep(1)}>
                            {t("button.back")}
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              )}

              {/* step3 === review modal */}
              {step === 3 && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                      <Dialog.Panel className="relative transform overflow-x-auto overflow-y-hidden rounded-lg bg-white sm:py-6 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full lg:max-w-4xl md:max-w-3xl">
                        <div>
                          <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900">
                              Review result
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Please review the following sample:
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="inline-block min-w-full align-middle px-4 pt-4">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  {resultHeader.length > 0 &&
                                    resultHeader.map((headerItem, index) => (
                                      <th
                                        key={index}
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        {headerItem}
                                      </th>
                                    ))}
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {resultBody.map(
                                  (bodyItem, idx) =>
                                    idx < 4 && (
                                      <tr key={idx}>
                                        {resultHeader.map(
                                          (specifiedHeader, i) => (
                                            <td
                                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                              key={i}>
                                              {Number(
                                                bodyItem[specifiedHeader]
                                              ) === 0 ||
                                              bodyItem[specifiedHeader] ===
                                                "Not known" ||
                                              bodyItem[specifiedHeader] ===
                                                "Unknown"
                                                ? ""
                                                : bodyItem[specifiedHeader]}
                                            </td>
                                          )
                                        )}
                                      </tr>
                                    )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 mx-4">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                            onClick={() => {
                              setLoading(true)
                              axiosInstance({
                                method: "post",
                                url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/cases/saveCSV`,
                                data: {
                                  parsedData: resultBody,
                                  userId: userId,
                                },
                              })
                                .then((res) => {
                                  setLogBooks(res.data)
                                })
                                .catch((err) => console.log(err))
                                .finally(() => {
                                  setLoading(false)
                                  setStep(4)
                                })
                            }}>
                            {t("button.next")}
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => {
                              setResultHeader([])
                              setResultBody([])
                              setStep(2)
                            }}>
                            {t("button.back")}
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              )}

              {/* step4 === complete modal */}
              {step === 4 && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                            <CheckIcon
                              className="h-6 w-6 text-green-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900">
                              Your import is complete!
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                {resultBody.length} cases were imported.{" "}
                                {parsedData.length - resultBody.length} were
                                ignored.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setOpenFileModal(false)}>
                            {t("button.close")}
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              )}
            </Dialog>
          </Transition.Root>
          {/* end opening file confirm modal */}
          {/* success modal */}
          <Transition.Root show={openSuccessModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={setOpenSuccessModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
            </Dialog>
          </Transition.Root>
          {/* end success modal */}
          {/* Spinner */}
          {loading && (
            <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100 flex items-center justify-center">
              <RingLoader
                color="#4f46e5"
                loading={loading}
                cssOverride={override}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          {/* End Spinner */}
        </>
      ) : (
        <GlobalSearch />
      )}
    </>
  )
}
