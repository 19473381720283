import React, { useEffect, useState, Fragment } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import DatePicker from "react-datepicker"
import { Dialog, Transition, Combobox } from "@headlessui/react"
import {
  CheckIcon,
  ChevronUpDownIcon,
  UserIcon,
} from "@heroicons/react/20/solid"
import axiosInstance from "../../utils/axios"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const EditDiagnosis = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const params = useParams()
  const userId = localStorage.getItem("id")

  const [open, setOpen] = useState(false) // for modal
  const [procedure, setProcedure] = useState("")
  const [date, setDate] = useState(new Date())
  const [location, setLocation] = useState("")
  const [no, setNo] = useState("")
  const [dob, setDob] = useState(new Date())
  const [age, setAge] = useState(0)
  const [gender, setGender] = useState("male")
  const [diagnosis, setDiagnosis] = useState("")
  const [complication, setComplication] = useState("")
  const [outcome, setOutcome] = useState("success")
  const [duration, setDuration] = useState(0)
  const [signedReviewers, setSignedReviewers] = useState([])
  const [caseTags, setCaseTags] = useState([]) // selected tags by doctors
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [query, setQuery] = useState("")
  const [people, setPeople] = useState([])
  const [reviewers, setReviewers] = useState([])

  // calculate age
  const calculate_age = (dob) => {
    const diff_ms = Date.now() - dob.getTime()
    const age_dt = new Date(diff_ms)
    return Math.abs(age_dt.getUTCFullYear() - 1970)
  }

  useEffect(() => {
    if (params.id) {
      axiosInstance({
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/case/handle`,
        params: {
          id: params.id,
        },
      })
        .then((res) => {
          setProcedure(res.data.case.mdlogbookcase.medicalprocedure.title)
          res.data.case.mdlogbookcase.date === null
            ? setDate("")
            : setDate(new Date(res.data.case.mdlogbookcase.date))
          res.data.case.mdlogbookcase.location === null
            ? setLocation("")
            : setLocation(res.data.case.mdlogbookcase.location)
          res.data.case.mdlogbookcase.no === null
            ? setNo("")
            : setNo(res.data.case.mdlogbookcase.no)
          res.data.case.mdlogbookcase.dob === null
            ? setDob("")
            : setDob(new Date(res.data.case.mdlogbookcase.dob))
          res.data.case.mdlogbookcase.age === null
            ? setAge("")
            : setAge(res.data.case.mdlogbookcase.age)
          setGender(res.data.case.mdlogbookcase.gender)
          res.data.case.mdlogbookcase.diagnosis === null
            ? setDiagnosis("")
            : setDiagnosis(res.data.case.mdlogbookcase.diagnosis)
          res.data.case.mdlogbookcase.complication === null
            ? setComplication("")
            : setComplication(res.data.case.mdlogbookcase.complication)
          setOutcome(res.data.case.mdlogbookcase.outcome)
          res.data.case.mdlogbookcase.duration === null
            ? setDuration("")
            : setDuration(res.data.case.mdlogbookcase.duration)
          setSignedReviewers(res.data.reviewers)
          setCaseTags(res.data.caseTags)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [params.id])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/all`,
    })
      .then((res) => {
        const temp = res.data.filter((item) => item.id !== Number(userId))
        setPeople(temp)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [userId])

  useEffect(() => {
    query === "" && setSelectedPerson(null)
  }, [query])

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
          const fullName = person.firstname + " " + person.lastname
          const lists =
            person.username.toLowerCase().includes(query.toLowerCase()) ||
            fullName.toLowerCase().includes(query.toLowerCase())
          return lists
        })

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
        <span className="text-2xl font-semibold text-gray-900">
          {procedure}
        </span>
        <div className="md:mt-0 mt-4">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            onClick={() => {
              navigate("/mdlogbook/cases")
            }}
          >
            {t("button.back")}
          </button>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="md:grid md:grid-cols-4 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t("mdlogbook.information")}
                </h3>
              </div>
            </div>
            <div className="mt-5 md:col-span-3 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md sm:mx-0 -mx-4">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-2 gap-6">
                    <div className="md:col-span-1 col-span-2">
                      <label className="block text-sm font-medium text-gray-700">
                        {t("mdlogbook.date")}
                      </label>
                      <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                      />
                    </div>

                    <div className="md:col-span-1 col-span-2">
                      <label className="block text-sm font-medium text-gray-700">
                        {t("mdlogbook.location")}
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("mdlogbook.patient")}
                  </h3>
                </div>
              </div>
              <div className="mt-5 md:col-span-3 md:mt-0">
                <div className="overflow-hidden shadow sm:rounded-md sm:mx-0 -mx-4">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-8 gap-8">
                      <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.medicalRecordNumber")}&nbsp;&nbsp;
                          <span className="text-sm font-normal text-gray-500">
                            ({t("mdlogbook.optional")})
                          </span>
                        </label>
                        <input
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                          value={no}
                          onChange={(e) => {
                            setNo(e.target.value)
                          }}
                        />
                      </div>

                      <div className="col-span-8 sm:col-span-4 xl:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.dateOfBirth")}&nbsp;&nbsp;
                          <span className="text-sm font-normal text-gray-500">
                            ({t("mdlogbook.optional")})
                          </span>
                        </label>
                        <DatePicker
                          selected={dob}
                          onChange={(date) => {
                            setDob(date)
                            setAge(calculate_age(new Date(date)))
                          }}
                          placeholderText="YYYY/MM/DD"
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>

                      <div className="col-span-8 sm:col-span-4 xl:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.age")}
                        </label>
                        <p className="mt-3">{age}</p>
                      </div>

                      <div className="col-span-8 sm:col-span-4 xl:col-span-2">
                        <legend className="contents text-base font-medium text-gray-900">
                          {t("mdlogbook.gender")}
                        </legend>
                        <div className="mt-4 flex items-center">
                          <div className="flex items-center pr-4">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="Male"
                              checked={gender === "Male"}
                              onChange={(e) => {
                                setGender(e.target.value)
                              }}
                            />
                            <label className="lg:ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.male")}
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="Female"
                              checked={gender === "Female"}
                              onChange={(e) => {
                                setGender(e.target.value)
                              }}
                            />
                            <label className="lg:ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.female")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("mdlogbook.details")}
                  </h3>
                </div>
              </div>
              <div className="mt-5 md:col-span-3 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md sm:mx-0 -mx-4">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.tags")}
                        </label>
                        <div className="mt-2">
                          {caseTags.length > 0 &&
                            caseTags.map((caseTag, index) => (
                              <span
                                key={index}
                                className="inline-flex items-center rounded-full bg-sky-100 py-0.5 px-3 text-sm font-medium text-sky-700 mr-2"
                              >
                                {caseTag.tag.name}
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="col-span-2 lg:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.diagnosis-description")}
                        </label>
                        <div className="mt-1">
                          <textarea
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                            value={diagnosis}
                            onChange={(e) => {
                              setDiagnosis(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.complications")}
                        </label>
                        <div className="mt-1">
                          <textarea
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                            value={complication}
                            onChange={(e) => {
                              setComplication(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <fieldset className="col-span-2 lg:col-span-1">
                        <legend className="contents text-base font-medium text-gray-900">
                          {t("mdlogbook.outcome")}&nbsp;&nbsp;
                          <span className="text-sm font-normal text-gray-500">
                            ({t("mdlogbook.optional")})
                          </span>
                        </legend>
                        <div className="lg:mt-4 flex items-center">
                          <div className="flex items-center pr-4">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="success"
                              checked={outcome === "success"}
                              onChange={(e) => {
                                setOutcome(e.target.value)
                              }}
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.success")}
                            </label>
                          </div>
                          <div className="flex items-center pr-4">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="failure"
                              checked={outcome === "failure"}
                              onChange={(e) => {
                                setOutcome(e.target.value)
                              }}
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.failure")}
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                              value="notApplicable"
                              checked={outcome === "notApplicable"}
                              onChange={(e) => {
                                setOutcome(e.target.value)
                              }}
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">
                              {t("mdlogbook.notApplicable")}
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <div className="col-span-2 lg:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          {t("mdlogbook.duration")} (mins)&nbsp;&nbsp;
                          <span className="text-sm font-normal text-gray-500">
                            ({t("mdlogbook.optional")})
                          </span>
                        </label>
                        <input
                          type="number"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                          value={duration}
                          onChange={(e) => {
                            setDuration(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("mdlogbook.reviewer")}
                  </h3>
                </div>
              </div>
              <div className="mt-5 md:col-span-3 md:mt-0">
                <div className="shadow sm:overflow-visible sm:rounded-md outline outline-1 outline-offset-0 outline-gray-200 sm:mx-0 -mx-4">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-4">
                    {signedReviewers.length > 0 && (
                      <div>
                        <p className="font-medium">
                          {t("mdlogbook.signedOff")}
                        </p>
                        {signedReviewers.map((reviewer, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center py-0.5 pr-8 text-sm font-medium mt-2"
                          >
                            <div className="text-blue-700">
                              {reviewer.user.firstname} {reviewer.user.lastname}
                            </div>
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="space-y-2 bg-white px-4 pb-5 sm:px-6 sm:pb-6">
                    <div className="grid grid-cols-3 gap-3">
                      <div className="col-span-3 lg:col-span-1">
                        <Combobox
                          as="div"
                          value={selectedPerson}
                          onChange={setSelectedPerson}
                        >
                          <div className="relative">
                            <Combobox.Input
                              className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                              onChange={(event) => setQuery(event.target.value)}
                              displayValue={(person) =>
                                person
                                  ? person.firstname + " " + person.lastname
                                  : ""
                              }
                              placeholder="Enter a name..."
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button>

                            {filteredPeople.length > 0 && (
                              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm top-auto bottom-full">
                                {filteredPeople.map((person) => (
                                  <Combobox.Option
                                    key={person.id}
                                    value={person}
                                    className={({ active }) =>
                                      classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                        active
                                          ? "bg-sky-600 text-white"
                                          : "text-gray-900"
                                      )
                                    }
                                  >
                                    {({ active, selected }) => (
                                      <>
                                        <div className="flex">
                                          <span
                                            className={classNames(
                                              "block truncate",
                                              selected && "font-semibold"
                                            )}
                                          >
                                            {person.firstname} {person.lastname}
                                          </span>
                                          <span
                                            className={classNames(
                                              "ml-2 truncate text-gray-500",
                                              active
                                                ? "text-sky-200"
                                                : "text-gray-500"
                                            )}
                                          >
                                            @{person.username}
                                          </span>
                                        </div>

                                        {selected && (
                                          <span
                                            className={classNames(
                                              "absolute inset-y-0 right-0 flex items-center pr-4",
                                              active
                                                ? "text-white"
                                                : "text-sky-600"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))}
                              </Combobox.Options>
                            )}
                          </div>
                        </Combobox>
                      </div>
                      <div className="col-span-3 lg:col-span-1">
                        <button
                          className={classNames(
                            "inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 bg-sky-600",
                            selectedPerson
                              ? "hover:bg-sky-700"
                              : "hover:bg-sky-600 opacity-50 cursor-default"
                          )}
                          onClick={() => {
                            if (selectedPerson) {
                              setReviewers((_prev) => {
                                const tmp = [..._prev]
                                for (let i = 0; i < tmp.length; i++) {
                                  if (tmp[i].id === selectedPerson.id) {
                                    return tmp
                                  }
                                }
                                tmp.push(selectedPerson)
                                return tmp
                              })
                              setSelectedPerson(null)
                            } else {
                              setSelectedPerson(null)
                              return
                            }
                          }}
                        >
                          {t("button.add")}
                        </button>
                      </div>
                    </div>
                    <div>
                      {reviewers.length > 0 &&
                        reviewers.map((reviewer, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center rounded-full bg-sky-100 py-0.5 pl-1 pr-1 text-sm font-medium text-sky-700 mt-2 mr-2"
                          >
                            {reviewer.avatar ? (
                              <img
                                alt="avatar"
                                src={reviewer.avatar}
                                className="rounded-full w-8 h-8 mr-2"
                              />
                            ) : (
                              <UserIcon
                                className="text-white w-8 h-8 mr-2"
                                aria-hidden="true"
                              />
                            )}
                            <div>
                              {reviewer.firstname} {reviewer.lastname}
                            </div>
                            <button
                              type="button"
                              className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-sky-400 hover:bg-sky-200 hover:text-sky-500 focus:bg-sky-500 focus:text-white focus:outline-none"
                              onClick={() => {
                                setReviewers((_prev) => {
                                  const tmp = [..._prev]
                                  const result = tmp.filter(
                                    (item) => item.id !== reviewer.id
                                  )
                                  return result
                                })
                              }}
                            >
                              <span className="sr-only">
                                Remove large option
                              </span>
                              <svg
                                className="h-2 w-2"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 8 8"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeWidth="1.5"
                                  d="M1 1l6 6m0-6L1 7"
                                />
                              </svg>
                            </button>
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pl-4 pt-3 text-right sm:pl-6">
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mr-2"
              onClick={() => {
                axiosInstance({
                  method: "delete",
                  url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/delete`,
                  data: {
                    id: params.id,
                    userId: userId,
                  },
                })
                  .then(() => {
                    navigate("/mdlogbook/cases")
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              }}
            >
              {t("button.delete")}
            </button>
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 mr-2"
              onClick={() => {
                const data = {
                  id: params.id,
                  date: date,
                  location: location,
                  no: no,
                  dob: dob,
                  age: age,
                  gender: gender,
                  diagnosis: diagnosis,
                  complication: complication,
                  outcome: outcome,
                  duration: duration,
                  reviewers: reviewers,
                }
                axiosInstance({
                  method: "put",
                  url: `${process.env.REACT_APP_BASE_URL}/api/mdlogbook/update`,
                  data: data,
                })
                  .then((res) => {
                    if (res.data === "success") {
                      setOpen(true)
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              }}
            >
              {t("button.save")}
            </button>
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              onClick={(e) => {
                e.preventDefault()
                navigate("/mdlogbook/cases")
              }}
            >
              {t("button.close")}
            </button>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {t("modal.updatedSuccessfulTitle")}
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => {
                        setOpen(false)
                        navigate("/mdlogbook/cases")
                      }}
                    >
                      {t("modal.backToList")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
