import React, { useState, useEffect, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Switch } from "@headlessui/react"
import axiosInstance from "../../utils/axios"
import { useContent } from "../../context/useContent"
import { GlobalSearch } from "../GlobalSearch"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Setting = () => {
  const { t } = useTranslation("translation")
  const savedData = useContent()

  const [dailyNotificationEnabled, setDailyNotificationEnabled] =
    useState(false)
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [email, setEmail] = useState("")
  const id = localStorage.getItem("id")

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/userId/${id}`,
    })
      .then((res) => {
        setDailyNotificationEnabled(res.data.notification)
        setEmail(res.data.email)
      })
      .catch((err) => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [id])

  const handleChange = (e) => {
    axiosInstance({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/users/storeTimezone`,
      data: {
        userTimezone: userTimezone,
        dailyNotificationEnabled: e,
        email: email,
      },
    })
      .then((res) => {
        setDailyNotificationEnabled(e)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      {savedData.searchResult === "" ? (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
            <span className="text-2xl font-semibold text-gray-900">
              {t("sidebar.setting")}
            </span>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <div className="py-4">
              <div className="mt-5 md:col-span-3 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md sm:mx-0 -mx-4">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div className="col-span-6">
                      <Switch.Group as="div" className="flex items-center">
                        <Switch
                          checked={dailyNotificationEnabled}
                          onChange={handleChange}
                          className={classNames(
                            dailyNotificationEnabled
                              ? "bg-sky-600"
                              : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              dailyNotificationEnabled
                                ? "translate-x-5"
                                : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>
                        <Switch.Label as="span" className="ml-3 text-sm">
                          <span className="font-medium text-gray-900">
                            {t("setting.dailySummaryNotification")}
                          </span>
                        </Switch.Label>
                      </Switch.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <GlobalSearch />
      )}
    </>
  )
}
