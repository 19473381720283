import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Transition, Dialog } from "@headlessui/react";
import { MagnifyingGlassIcon, CheckIcon, ClockIcon } from "@heroicons/react/20/solid";
import axiosInstance from "../../utils/axios";
import { GlobalSearch } from "../GlobalSearch";
import { useContent } from "../../context/useContent";
import { Tooltip } from "../../components/Tooltip";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Review = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const savedData = useContent();

  // for modal
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  // end
  const [logBooks, setLogBooks] = useState([]);
  const [id, setId] = useState(0);
  const [enabled, setEnabled] = useState(false); // for switch lists/cards view
  const [batchList, setBatchList] = useState([]);
  const [currentView, setCurrentView] = useState("pending");
  const [currentPage, setCurrentPage] = useState(1);
  const casesPerPage = 30;
  const [validationOpen, setValidationOpen] = useState(false);

  const userId = localStorage.getItem("id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/reviews/all`, {
          params: {
            userId: userId,
          },
        });
        setLogBooks(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [userId]);

  const confirmUpdateSigns = async () => {
    try {
      const optimizedData = {
        batchList: batchList,
        logBooks: logBooks.map(caseItem => ({
          id: caseItem.case.id,
        })),
      };

      await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/api/reviews/updateSigns`,
        optimizedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLogBooks(prevLogBooks =>
        prevLogBooks.map(item =>
          batchList.includes(item.case.id)
            ? { ...item, case: { ...item.case, signed: true } }
            : item
        )
      );
      setBatchList([]);
      setValidationOpen(false);
    } catch (error) {
      console.error("Error updating signs:", error);
    }
  };

  const handleUpdateSign = async (caseId) => {
    try {
      const caseInfo = logBooks.find(caseItem => caseItem.case.id === caseId);
      const optimizedCaseInfo = {
        id: caseInfo.case.id,
      };
      await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/api/reviews/updateSign/${caseId}`,
        { case: optimizedCaseInfo },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLogBooks(prevLogBooks =>
        prevLogBooks.map(item =>
          item.case.id === caseId ? { ...item, case: { ...item.case, signed: true } } : item
        )
      );

      setOpen(false);
    } catch (error) {
      console.error("Error updating sign:", error);
    }
  };

  const handleUpdateSigns = () => {
    if (batchList.length > 0) {
      setValidationOpen(true);
    }
  };

  // Filter the logBooks into reviewed and pending cases
  const reviewedCases = logBooks.filter(item => item.case.signed);
  const pendingCases = logBooks.filter(item => !item.case.signed);

  // Pagination logic
  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = currentView === "pending" ? pendingCases.slice(indexOfFirstCase, indexOfLastCase) : reviewedCases.slice(indexOfFirstCase, indexOfLastCase);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {savedData.searchResult === "" ? (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between mt-4">
            <span className="text-2xl font-semibold text-gray-900">
              {t("mdlogbook.review")}
            </span>
            <div className="md:mt-0 mt-4 flex items-center">
              <span className="isolate inline-flex rounded-md shadow-sm mr-2">
                <button
                  type="button"
                  className={classNames(
                    enabled ? "bg-white hover:bg-gray-50" : "bg-gray-100",
                    "relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
                  )}
                  onClick={() => setEnabled(false)}
                >
                  {t("mdlogbook.list")}
                </button>
                <button
                  type="button"
                  className={classNames(
                    enabled ? "bg-gray-100" : "bg-white hover:bg-gray-50",
                    "relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
                  )}
                  onClick={() => setEnabled(true)}
                >
                  {t("mdlogbook.cards")}
                </button>
              </span>
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 mr-2"
                onClick={() => {
                  navigate("/mdlogbook");
                }}
              >
                {t("button.back")}
              </button>
              <button
                type="button"
                className={classNames(
                  batchList.length > 0
                    ? "hover:bg-sky-700 focus:ring-sky-500 focus:ring-2"
                    : "opacity-50 cursor-default",
                  "inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-offset-2 bg-sky-600"
                )}
                onClick={handleUpdateSigns}
              >
                {t("button.signOff")}
              </button>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 mt-4">
            <div className="flex space-x-4 mb-4">
              <button
                type="button"
                className={classNames(
                  currentView === "pending" ? "bg-sky-600 text-white" : "bg-gray-100",
                  "px-4 py-2 rounded-md text-sm font-semibold"
                )}
                onClick={() => {
                  setCurrentView("pending");
                  setCurrentPage(1);
                }}
              >
                {t("Pending Cases")}
              </button>
              <button
                type="button"
                className={classNames(
                  currentView === "reviewed" ? "bg-sky-600 text-white" : "bg-gray-100",
                  "px-4 py-2 rounded-md text-sm font-semibold"
                )}
                onClick={() => {
                  setCurrentView("reviewed");
                  setCurrentPage(1);
                }}
              >
                {t("Reviewed Cases")}
              </button>
            </div>
            <div className="py-4">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {t("mdlogbook.learner")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            {t("mdlogbook.procedure")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            {t("mdlogbook.age")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            {t("mdlogbook.gender")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            {t("mdlogbook.date")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            {t("mdlogbook.reviewer")}
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {currentCases.map((item) => (
                          <tr key={item.case.id}>
                            {item.case.mdlogbookcase !== null && (
                              <Fragment>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 flex items-center">
                                  {item.case.signed ? (
                                    <input
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 mr-2 opacity-50"
                                      disabled
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 mr-2"
                                      onChange={(e) => {
                                        setBatchList((_prev) => {
                                          const temp = [..._prev];
                                          if (e.target.checked) {
                                            temp.push(item.case.id);
                                            return temp;
                                          } else {
                                            return temp.filter(
                                              (caseId) =>
                                                caseId !== item.case.id
                                            );
                                          }
                                        });
                                      }}
                                    />
                                  )}
                                  {item.case.mdlogbookcase.user ? (
                                    <>
                                      {item.case.mdlogbookcase.user.firstname}{" "}
                                      {item.case.mdlogbookcase.user.lastname}
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {
                                    item.case.mdlogbookcase.medicalprocedure
                                      .title
                                  }
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.case.mdlogbookcase.age === 0
                                    ? ""
                                    : item.case.mdlogbookcase.age}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.case.mdlogbookcase.gender}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {new Date(
                                    item.case.mdlogbookcase.date
                                  ).toLocaleDateString()}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.signers.length > 0 &&
                                    item.signers.map((signer, index) => (
                                      <p key={index}>
                                        {signer.firstname &&
                                        signer.lastname ? (
                                          <>
                                            {signer.firstname}{" "}
                                            {signer.lastname}
                                          </>
                                        ) : (
                                          <>{signer.username}</>
                                        )}
                                      </p>
                                    ))}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pr-6 text-right text-sm font-medium flex items-center justify-end">
                                  <button
                                    className="text-sky-600 hover:text-sky-900 px-2"
                                    onClick={() => {
                                      navigate(
                                        `/mdlogbook/review/${item.case.mdlogbookcase.id}`
                                      );
                                    }}
                                  >
                                    <Tooltip message="View">
                                      <MagnifyingGlassIcon
                                        className="-mr-1 ml-2 h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </Tooltip>
                                    <span className="sr-only">
                                      , {item.case.id}
                                    </span>
                                  </button>
                                  {item.case.signed ? (
                                    <button className="text-sky-600 hover:text-sky-900">
                                      <Tooltip
                                        message="Reviewed"
                                        className="-left-5"
                                      >
                                        <CheckIcon
                                          className="ml-2 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </Tooltip>
                                      <span className="sr-only">
                                        , {item.case.id}
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      className="text-sky-600 hover:text-sky-900"
                                      onClick={() => {
                                        setId(item.case.id);
                                        setOpen(true);
                                      }}
                                    >
                                      <Tooltip
                                        message="Pending review"
                                        className="-left-14"
                                      >
                                        <ClockIcon
                                          className="ml-2 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </Tooltip>
                                      <span className="sr-only">
                                        , {item.case.id}
                                      </span>
                                    </button>
                                  )}
                                </td>
                              </Fragment>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {currentView === "pending" && pendingCases.length === 0 && (
                      <p className="text-center py-5 bg-white border-t">
                        You currently have no pending cases to review.
                      </p>
                    )}
                    {currentView === "reviewed" && reviewedCases.length === 0 && (
                      <p className="text-center py-5 bg-white border-t">
                        You currently have no reviewed cases.
                      </p>
                    )}
                    {currentCases.length > 0 && (
                      <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                        <div className="flex items-center justify-between">
                          <div className="flex-1 flex justify-between sm:hidden">
                            <button
                              onClick={() => paginate(currentPage - 1)}
                              disabled={currentPage === 1}
                              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                              Previous
                            </button>
                            <button
                              onClick={() => paginate(currentPage + 1)}
                              disabled={currentPage * casesPerPage >= (currentView === "pending" ? pendingCases.length : reviewedCases.length)}
                              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                              Next
                            </button>
                          </div>
                          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                              <p className="text-sm text-gray-700">
                                Showing <span className="font-medium">{indexOfFirstCase + 1}</span> to <span className="font-medium">{Math.min(indexOfLastCase, currentView === "pending" ? pendingCases.length : reviewedCases.length)}</span> of{" "}
                                <span className="font-medium">{currentView === "pending" ? pendingCases.length : reviewedCases.length}</span> results
                              </p>
                            </div>
                            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                              <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                              >
                                <span className="sr-only">Previous</span>
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12.293 14.707a1 1 0 010-1.414L15.586 10 12.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                              {Array.from({ length: Math.ceil((currentView === "pending" ? pendingCases.length : reviewedCases.length) / casesPerPage) }, (_, index) => (
                                <button
                                  key={index + 1}
                                  onClick={() => paginate(index + 1)}
                                  className={classNames(
                                    currentPage === index + 1 ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600" : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50",
                                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                  )}
                                >
                                  {index + 1}
                                </button>
                              ))}
                              <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage * casesPerPage >= (currentView === "pending" ? pendingCases.length : reviewedCases.length)}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                              >
                                <span className="sr-only">Next</span>
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.707 14.707a1 1 0 01-1.414-1.414L10.586 10 6.293 5.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </nav>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <CheckIcon
                              className="h-6 w-6 text-green-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              {t("modal.checkCaseTitle")}
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                {t("modal.checkCaseContent")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                            handleUpdateSign(id);
                          }}
                        >
                          {t("button.signOff")}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          {t("modal.cancel")}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Validation Modal for Batch Sign-Off */}
          <Transition.Root show={validationOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={setValidationOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <CheckIcon
                              className="h-6 w-6 text-green-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              {t("Sign-off")}
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                {t("By signing-off, you are confirming that you have personally reviewed this case.")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={confirmUpdateSigns}
                        >
                          {t("Confirm")}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => setValidationOpen(false)}
                          ref={cancelButtonRef}
                        >
                          {t("button.cancel")}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      ) : (
        <GlobalSearch />
      )}
    </>
  );
};



