// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker-wrapper .react-datepicker__input-container [type="text"] {
  border-color: #d1d5db;
  border-radius: 0.375rem;
  margin-top: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 38px;
  width: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MDLogBook/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".react-datepicker-wrapper .react-datepicker__input-container [type=\"text\"] {\n  border-color: #d1d5db;\n  border-radius: 0.375rem;\n  margin-top: 4px;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  height: 38px;\n  width: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
