import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import axiosInstance from "../../utils/axios"

export const User = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const [users, setUsers] = useState([])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/admin/users`,
    })
      .then((res) => {
        setUsers(res.data)
      })
      .catch((err) => {
        err && navigate("/dashboard")
      })
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
        <span className="text-2xl font-semibold text-gray-900">
          {t("admin.users")}
        </span>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="my-6 flow-root md:hidden">
                <ul className="-my-5 divide-y divide-gray-200 border-y bg-white">
                  {users.length > 0 &&
                    users.map((user) => (
                      <li key={user.id} className="py-4 px-4">
                        <div className="flex items-center space-x-3">
                          <div className="min-w-0 flex-1">
                            <p className="truncate text-sm text-gray-500">
                              <b>{t("profile.firstName")}:</b> {user.firstname}
                              &nbsp;&nbsp;&nbsp;
                              <b>{t("profile.lastName")}:</b> {user.lastname}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              <b>{t("profile.userName")}:</b> {user.username}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              <b>{t("profile.email")}:</b> {user.email}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              <b>{t("profile.timezone")}:</b> {user.timezone}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg hidden md:block">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {t("profile.firstName")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("profile.lastName")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("profile.userName")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("profile.email")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("profile.timezone")}
                      </th>
                      {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Action</span>
                  </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {users.length > 0 &&
                      users.map((user) => (
                        <tr key={user.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                            {user.firstname}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.lastname}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.username}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.timezone}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
