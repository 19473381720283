import { Route, Routes } from "react-router-dom"
import "./App.css"
// Pages
import { Home } from "./pages/Home"
///////// Authentication
import { Login } from "./pages/Login"
import { ForgotPassword } from "./pages/ForgotPassword"
import { ResetPassword } from "./pages/ResetPassword"
////////////////////////
import { Profile } from "./pages/Profile"
import { Dashboard } from "./pages/Dashboard"
///////// Mdlogbook
import { MDLogBook } from "./pages/MDLogBook"
import { CreateDiagnosis } from "./pages/MDLogBook/Create"
import { EditDiagnosis } from "./pages/MDLogBook/Edit"
import { LogSummary } from "./pages/MDLogBook/LogSummary"
import { LogCase } from "./pages/MDLogBook/LogCase"
import { Review } from "./pages/MDLogBook/Review"
import { ViewCase } from "./pages/MDLogBook/ViewCase"
////////////////////
import { Setting } from "./pages/Setting"
import { Notification } from "./pages/Notification"
import { Stat } from "./pages/Admin/Stat"
/////////////// Admin
import { Specialty } from "./pages/Admin/Specialty"
import { EditSpecialty } from "./pages/Admin/Specialty/edit"
import { CreateSpecialty } from "./pages/Admin/Specialty/create"
import { ProcedureGroup } from "./pages/Admin/ProcedureGroup"
import { CreateProcedureGroup } from "./pages/Admin/ProcedureGroup/create"
import { EditProcedureGroup } from "./pages/Admin/ProcedureGroup/edit"
import { Procedure } from "./pages/Admin/Procedure"
import { CreateProcedure } from "./pages/Admin/Procedure/create"
import { EditProcedure } from "./pages/Admin/Procedure/edit"
import { User } from "./pages/Admin/User"
import { Institution } from "./pages/Admin/Institution"
import { CreateInstitution } from "./pages/Admin/Institution/create"
import { EditInstitution } from "./pages/Admin/Institution/edit"
import { Tag } from "./pages/Admin/Tag"
import { CreateTag } from "./pages/Admin/Tag/create"
import { EditTag } from "./pages/Admin/Tag/edit"
import { InstitutionType } from "./pages/Admin/InstitutionType"
import { CreateInstitutionType } from "./pages/Admin/InstitutionType/create"
import { EditInstitutionType } from "./pages/Admin/InstitutionType/edit"
//////////////////////
// end Pages
// context
import { AuthProvider } from "./context/useAuth"
import { ContentProvider } from "./context/useContent"
// end context
import { NonPrivateRoute, PrivateRoute } from "./components/Router/PrivateRoute"
import { Layout } from "./components/Layout"
import "tw-elements"

function App() {
  return (
    <AuthProvider>
      <ContentProvider>
        <Routes>
          <Route
            path="/"
            element={
              <NonPrivateRoute>
                <Home />
              </NonPrivateRoute>
            }
          />
          <Route path="/signin" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Layout>
                  <Profile />
                </Layout>
              </PrivateRoute>
            }
          />
          {/* start mdlogbook */}
          <Route
            path="/mdlogbook"
            element={
              <PrivateRoute>
                <Layout>
                  <MDLogBook />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/mdlogbook/new-case"
            element={
              <PrivateRoute>
                <Layout>
                  <CreateDiagnosis />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/mdlogbook/summary"
            element={
              <PrivateRoute>
                <Layout>
                  <LogSummary />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/mdlogbook/cases"
            element={
              <PrivateRoute>
                <Layout>
                  <LogCase />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/mdlogbook/cases/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <EditDiagnosis />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/mdlogbook/review/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <ViewCase />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/mdlogbook/review"
            element={
              <PrivateRoute>
                <Layout>
                  <Review />
                </Layout>
              </PrivateRoute>
            }
          />
          {/* end mdlogbook */}
          {/* start setting page */}
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Layout>
                  <Setting />
                </Layout>
              </PrivateRoute>
            }
          />
          {/* end setting page */}
          {/* start admin page */}
          <Route
            path="/admin/specialties"
            element={
              <PrivateRoute>
                <Layout>
                  <Specialty />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/specialties/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <EditSpecialty />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/specialties/new-specialty"
            element={
              <PrivateRoute>
                <Layout>
                  <CreateSpecialty />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/proceduregroups"
            element={
              <PrivateRoute>
                <Layout>
                  <ProcedureGroup />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/proceduregroups/new-proceduregroup"
            element={
              <PrivateRoute>
                <Layout>
                  <CreateProcedureGroup />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/proceduregroups/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <EditProcedureGroup />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/procedures"
            element={
              <PrivateRoute>
                <Layout>
                  <Procedure />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/procedures/new-procedure"
            element={
              <PrivateRoute>
                <Layout>
                  <CreateProcedure />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/procedures/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <EditProcedure />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/users"
            element={
              <PrivateRoute>
                <Layout>
                  <User />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/institutions"
            element={
              <PrivateRoute>
                <Layout>
                  <Institution />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/institutions/new-institution"
            element={
              <PrivateRoute>
                <Layout>
                  <CreateInstitution />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/institutions/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <EditInstitution />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/institutiontypes"
            element={
              <PrivateRoute>
                <Layout>
                  <InstitutionType />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/institutiontypes/new-institutiontype"
            element={
              <PrivateRoute>
                <Layout>
                  <CreateInstitutionType />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/institutiontypes/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <EditInstitutionType />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/tags"
            element={
              <PrivateRoute>
                <Layout>
                  <Tag />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/tags/new-tag"
            element={
              <PrivateRoute>
                <Layout>
                  <CreateTag />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/tags/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <EditTag />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/stats"
            element={
              <PrivateRoute>
                <Layout>
                  <Stat />
                </Layout>
              </PrivateRoute>
            }
          />
          {/* end admin page */}
          <Route
            path="/notifications"
            element={
              <PrivateRoute>
                <Layout>
                  <Notification />
                </Layout>
              </PrivateRoute>
            }
          />
        </Routes>
      </ContentProvider>
    </AuthProvider>
  )
}

export default App
