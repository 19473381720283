import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Menu, Transition } from "@headlessui/react"
import {
  ChevronDownIcon,
  EyeIcon,
  EyeSlashIcon,
  ChartBarIcon,
  DocumentCheckIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid"
import axiosInstance from "../../utils/axios"
import { useContent } from "../../context/useContent"
import { GlobalSearch } from "../GlobalSearch"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const MDLogBook = () => {
  const { t } = useTranslation("translation")
  const navigate = useNavigate()
  const savedData = useContent()
  const userid = localStorage.getItem("id")
  const app = "mdlogbook"
  const settingkey = "procedure"
  const [dropdownMenu, setDropdownMenu] = useState(`${t("homepage.all")}`)
  const [filterValue, setFilterValue] = useState("all")
  const [medicalProcedureGroups, setMedicalProcedureGroups] = useState([])
  const [medicalProcedures, setMedicalProcedures] = useState([])
  const [showEyeIcon, setShowEyeIcon] = useState(false)
  const [resultGroup, setResultGroup] = useState([])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [screenType, setScreenType] = useState("Desktop")
  const [reviewCount, setReviewCount] = useState(0)

  window.onresize = function () {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/medical-procedure-groups/all`,
    })
      .then((res) => {
        setMedicalProcedureGroups(
          res.data.map((item) => {
            return {
              ...item,
              checked: true,
            }
          })
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (screenWidth >= 770) {
      setScreenType("Desktop")
    } else {
      setScreenType("Mobile")
    }
  }, [screenWidth])

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/api/medical-procedures/all`,
      params: {
        userid: userid,
        app: app,
        settingkey: settingkey,
      },
    })
      .then((res) => {
        setMedicalProcedures(
          res.data.medicalprocedures.map((item) => {
            return {
              ...item,
              checked: !res.data.value.filter((valueItem) => {
                return Number(valueItem) === Number(item.id)
              }).length
                ? true
                : false,
            }
          })
        )
      })
      .catch((err) => {
        console.log(err)
      })

      axiosInstance({
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/api/reviews/counts`,
        params: {
          userid: userid,
        },
      })
      .then((res) => {
        const count = res.data;
        setReviewCount(count > 9 ? '9+' : count.toString());  // Updated to handle counts greater than 9 as string
      })
      .catch((err) => {
        console.error('Error fetching review counts:', err);  // Updated error handling for better debugging
      });
    }, [userid, app, settingkey]);  // Assuming app and settingkey are relevant dependencies for this effect
    

  useEffect(() => {
    switch (filterValue) {
      case "cardiology":
        axiosInstance({
          method: "get",
          url: `${process.env.REACT_APP_BASE_URL}/api/medical-procedure-groups/cardiology`,
        })
          .then((res) => {
            setMedicalProcedureGroups(
              res.data.map((item) => {
                return {
                  ...item,
                  checked: true,
                }
              })
            )
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case "respirology":
        axiosInstance({
          method: "get",
          url: `${process.env.REACT_APP_BASE_URL}/api/medical-procedure-groups/respirology`,
        })
          .then((res) => {
            setMedicalProcedureGroups(
              res.data.map((item) => {
                return {
                  ...item,
                  checked: true,
                }
              })
            )
          })
          .catch((err) => {
            console.log(err)
          })
        break
      default:
        axiosInstance({
          method: "get",
          url: `${process.env.REACT_APP_BASE_URL}/api/medical-procedure-groups/all`,
        })
          .then((res) => {
            setMedicalProcedureGroups(
              res.data.map((item) => {
                return {
                  ...item,
                  checked: true,
                }
              })
            )
          })
          .catch((err) => {
            console.log(err)
          })
        break
    }
  }, [filterValue])

  useEffect(() => {
    const tmp = medicalProcedureGroups.map((group) => {
      const items = medicalProcedures.filter(
        (procedure) => procedure.groupid === group.id
      )
      for (let i = 0; i < items.length; i++) {
        if (items[i].checked === true) {
          return group
        }
      }
      return { ...group, checked: false }
    })
    setResultGroup(tmp)
  }, [medicalProcedureGroups, medicalProcedures])

  return (
    <>
      {savedData.searchResult === "" ? (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 lg:flex md:items-center md:justify-between">
            <span className="text-2xl font-semibold text-gray-900 md:mb-0 mb-4">
              {t("sidebar.mdlogbook")}
            </span>
            <div className="lg:mt-0 mt-4 flex items-center">
              <Menu as="div" className="relative inline-block text-left mr-2">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    {dropdownMenu}
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute md:right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm border-b-2"
                            )}
                            onClick={() => {
                              setDropdownMenu(t("homepage.all"))
                              setFilterValue("all")
                            }}
                          >
                            {t("homepage.all")}
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm"
                            )}
                            onClick={() => {
                              setDropdownMenu(t("homepage.cardiology"))
                              setFilterValue("cardiology")
                            }}
                          >
                            {t("homepage.cardiology")}
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm"
                            )}
                            onClick={() => {
                              setDropdownMenu(t("homepage.respirology"))
                              setFilterValue("respirology")
                            }}
                          >
                            {t("homepage.respirology")}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 mr-2"
                onClick={() => {
                  navigate("/mdlogbook/summary")
                }}
              >
                {screenType === "Desktop" ? (
                  t("mdlogbook.summary")
                ) : (
                  <ChartBarIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </button>
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 mr-2"
                onClick={() => {
                  navigate("/mdlogbook/cases")
                }}
              >
                {screenType === "Desktop" ? (
                  t("mdlogbook.cases")
                ) : (
                  <UserGroupIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </button>
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 mr-2"
                onClick={() => {
                  navigate("/mdlogbook/review")
                }}
              >
                {screenType === "Desktop" ? (
                  <>
                    {t("mdlogbook.review")}
                    {reviewCount > 0 && (
                      <>
                        &nbsp;&nbsp;
                        <span className="inline-block rounded-full bg-red-600 w-5">
                          {reviewCount}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <DocumentCheckIcon className="h-5 w-5" aria-hidden="true" />
                    {reviewCount > 0 && (
                      <>
                        &nbsp;&nbsp;
                        <span className="inline-block rounded-full bg-red-600 w-5">
                          {reviewCount}
                        </span>
                      </>
                    )}
                  </>
                )}
              </button>
              <button
                type="button"
                className={classNames(
                  showEyeIcon ? "bg-sky-700" : "bg-sky-600",
                  "inline-flex rounded-md border border-transparent px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                )}
                onClick={() => {
                  setShowEyeIcon(!showEyeIcon)
                }}
              >
                <EyeIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-0 sm:px-6 md:px-8">
            <div className="py-4">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full">
                  <thead></thead>
                  <tbody className="bg-white">
                    {resultGroup.map((medicalproceduregroup, index) =>
                      !showEyeIcon && !medicalproceduregroup.checked ? null : (
                        <Fragment key={medicalproceduregroup.id}>
                          <tr className="border-t border-gray-200">
                            <th
                              scope="colgroup"
                              className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                            >
                              {medicalproceduregroup.title}
                            </th>
                            {showEyeIcon && (
                              <th className="bg-gray-50">
                                {medicalproceduregroup.checked ? (
                                  <EyeIcon
                                    className="h-5 w-5 cursor-pointer"
                                    aria-hidden="true"
                                    onClick={() => {
                                      let procedures = []
                                      medicalProcedures.forEach(
                                        (medicalProcedure) => {
                                          if (
                                            medicalproceduregroup.id ===
                                            medicalProcedure.groupid
                                          ) {
                                            medicalProcedure.checked = false
                                            procedures.push(medicalProcedure)
                                          }
                                        }
                                      )
                                      const data = {
                                        userid: userid,
                                        app: app,
                                        procedure: procedures,
                                      }
                                      axiosInstance({
                                        method: "post",
                                        url: `${process.env.REACT_APP_BASE_URL}/api/app-settings/group`,
                                        data: data,
                                      })
                                        .then((res) => {
                                          if (res.data === "success") {
                                            setResultGroup((_prev) => {
                                              const tmp = [..._prev]
                                              tmp[index] = {
                                                ...tmp[index],
                                                checked: !tmp[index].checked,
                                              }
                                              return tmp
                                            })
                                          }
                                        })
                                        .catch((err) => {
                                          console.log(err)
                                        })
                                    }}
                                  />
                                ) : (
                                  <EyeSlashIcon
                                    className="h-5 w-5 cursor-pointer"
                                    aria-hidden="true"
                                    onClick={() => {
                                      let procedures = []
                                      medicalProcedures.forEach(
                                        (medicalProcedure) => {
                                          if (
                                            medicalproceduregroup.id ===
                                            medicalProcedure.groupid
                                          ) {
                                            medicalProcedure.checked = true
                                            procedures.push(medicalProcedure)
                                          }
                                        }
                                      )
                                      const data = {
                                        userid: userid,
                                        app: app,
                                        procedure: procedures,
                                      }
                                      axiosInstance({
                                        method: "post",
                                        url: `${process.env.REACT_APP_BASE_URL}/api/app-settings/group`,
                                        data: data,
                                      })
                                        .then((res) => {
                                          if (res.data === "success") {
                                            setResultGroup((_prev) => {
                                              const tmp = [..._prev]
                                              tmp[index] = {
                                                ...tmp[index],
                                                checked: !tmp[index].checked,
                                              }
                                              return tmp
                                            })
                                          }
                                        })
                                        .catch((err) => {
                                          console.log(err)
                                        })
                                    }}
                                  />
                                )}
                              </th>
                            )}
                          </tr>
                          {medicalProcedures.map(
                            (medicalprocedure, idx) =>
                              medicalproceduregroup.id ===
                                medicalprocedure.groupid &&
                              (!showEyeIcon &&
                              !medicalprocedure.checked ? null : (
                                <tr
                                  key={medicalprocedure.id}
                                  className={classNames(
                                    idx === 0
                                      ? "border-gray-300"
                                      : "border-gray-200",
                                    medicalprocedure.checked
                                      ? ""
                                      : "bg-gray-200",
                                    "border-t"
                                  )}
                                >
                                  <td
                                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 cursor-pointer"
                                    onClick={() => {
                                      if (showEyeIcon) {
                                        if (medicalprocedure.checked) {
                                          const changeddata = {
                                            ...medicalprocedure,
                                            checked: false,
                                          }
                                          const data = {
                                            userid: userid,
                                            app: app,
                                            procedure: changeddata,
                                          }
                                          axiosInstance({
                                            method: "post",
                                            url: `${process.env.REACT_APP_BASE_URL}/api/app-settings/item`,
                                            data: data,
                                          })
                                            .then((res) => {
                                              if (res.data === "success") {
                                                setMedicalProcedures(
                                                  (_prev) => {
                                                    const tmp = [..._prev]
                                                    tmp[idx] = {
                                                      ...tmp[idx],
                                                      checked:
                                                        !tmp[idx].checked,
                                                    }
                                                    return tmp
                                                  }
                                                )
                                                for (
                                                  let i = 0;
                                                  i < medicalProcedures.length;
                                                  i++
                                                ) {
                                                  if (
                                                    medicalproceduregroup.id ===
                                                    medicalProcedures[i].groupid
                                                  ) {
                                                    if (
                                                      medicalProcedures[i]
                                                        .checked === true &&
                                                      i !== idx
                                                    )
                                                      return
                                                  }
                                                }
                                                setResultGroup((_prev) => {
                                                  const tmp = [..._prev]
                                                  tmp[index] = {
                                                    ...tmp[index],
                                                    checked: false,
                                                  }
                                                  return tmp
                                                })
                                              }
                                            })
                                            .catch((err) => {
                                              console.log(err)
                                            })
                                        } else {
                                          const changeddata = {
                                            ...medicalprocedure,
                                            checked: true,
                                          }
                                          const data = {
                                            userid: userid,
                                            app: app,
                                            procedure: changeddata,
                                          }
                                          axiosInstance({
                                            method: "post",
                                            url: `${process.env.REACT_APP_BASE_URL}/api/app-settings/item`,
                                            data: data,
                                          })
                                            .then((res) => {
                                              if (res.data === "success") {
                                                setMedicalProcedures(
                                                  (_prev) => {
                                                    const tmp = [..._prev]
                                                    tmp[idx] = {
                                                      ...tmp[idx],
                                                      checked:
                                                        !tmp[idx].checked,
                                                    }
                                                    return tmp
                                                  }
                                                )
                                                setResultGroup((_prev) => {
                                                  const tmp = [..._prev]
                                                  tmp[index] = {
                                                    ...tmp[index],
                                                    checked: true,
                                                  }
                                                  return tmp
                                                })
                                              }
                                            })
                                            .catch((err) => {
                                              console.log(err)
                                            })
                                        }
                                      } else {
                                        localStorage.setItem(
                                          "procedureId",
                                          medicalprocedure.id
                                        )
                                        navigate("/mdlogbook/new-case")
                                      }
                                    }}
                                  >
                                    {medicalprocedure.title}
                                  </td>
                                  {showEyeIcon && (
                                    <td className="md:pr-0 pr-1">
                                      {medicalprocedure.checked ? (
                                        <EyeIcon
                                          className="h-5 w-5 cursor-pointer"
                                          aria-hidden="true"
                                          onClick={() => {
                                            const changeddata = {
                                              ...medicalprocedure,
                                              checked: false,
                                            }
                                            const data = {
                                              userid: userid,
                                              app: app,
                                              procedure: changeddata,
                                            }
                                            axiosInstance({
                                              method: "post",
                                              url: `${process.env.REACT_APP_BASE_URL}/api/app-settings/item`,
                                              data: data,
                                            })
                                              .then((res) => {
                                                if (res.data === "success") {
                                                  setMedicalProcedures(
                                                    (_prev) => {
                                                      const tmp = [..._prev]
                                                      tmp[idx] = {
                                                        ...tmp[idx],
                                                        checked:
                                                          !tmp[idx].checked,
                                                      }
                                                      return tmp
                                                    }
                                                  )
                                                  for (
                                                    let i = 0;
                                                    i <
                                                    medicalProcedures.length;
                                                    i++
                                                  ) {
                                                    if (
                                                      medicalproceduregroup.id ===
                                                      medicalProcedures[i]
                                                        .groupid
                                                    ) {
                                                      if (
                                                        medicalProcedures[i]
                                                          .checked === true &&
                                                        i !== idx
                                                      )
                                                        return
                                                    }
                                                  }
                                                  setResultGroup((_prev) => {
                                                    const tmp = [..._prev]
                                                    tmp[index] = {
                                                      ...tmp[index],
                                                      checked: false,
                                                    }
                                                    return tmp
                                                  })
                                                }
                                              })
                                              .catch((err) => {
                                                console.log(err)
                                              })
                                          }}
                                        />
                                      ) : (
                                        <EyeSlashIcon
                                          className="h-5 w-5 cursor-pointer"
                                          aria-hidden="true"
                                          onClick={() => {
                                            const changeddata = {
                                              ...medicalprocedure,
                                              checked: true,
                                            }
                                            const data = {
                                              userid: userid,
                                              app: app,
                                              procedure: changeddata,
                                            }
                                            axiosInstance({
                                              method: "post",
                                              url: `${process.env.REACT_APP_BASE_URL}/api/app-settings/item`,
                                              data: data,
                                            })
                                              .then((res) => {
                                                if (res.data === "success") {
                                                  setMedicalProcedures(
                                                    (_prev) => {
                                                      const tmp = [..._prev]
                                                      tmp[idx] = {
                                                        ...tmp[idx],
                                                        checked:
                                                          !tmp[idx].checked,
                                                      }
                                                      return tmp
                                                    }
                                                  )
                                                  setResultGroup((_prev) => {
                                                    const tmp = [..._prev]
                                                    tmp[index] = {
                                                      ...tmp[index],
                                                      checked: true,
                                                    }
                                                    return tmp
                                                  })
                                                }
                                              })
                                              .catch((err) => {
                                                console.log(err)
                                              })
                                          }}
                                        />
                                      )}
                                    </td>
                                  )}
                                </tr>
                              ))
                          )}
                        </Fragment>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <GlobalSearch />
      )}
    </>
  )
}
