import Login from "./login.svg"
import LoginAvatar from "./login_avatar.png"
import Google from "./google.svg"
import Avatar from "./avatar.svg"

const images = {
  Login,
  LoginAvatar,
  Google,
  Avatar,
}

export default images
